import React, { useState } from 'react';
import {
    ComposedModal,
    ModalHeader,
    ModalBody,
    TextInput,
    Theme,
    ModalFooter,
} from '@carbon/react';
import { shareAPI } from '../../../modules/api/share';
import { zIndex } from 'modules/defines/styles';

const PinModal = ({ isOpen, setIsOpen, setPatientInfo, setIsError }) => {
    const [email, setEmail] = useState('');
    const [pin, setPin] = useState();

    const handleSend = () => {
        const rqData = {
            email: email && email,
            pin: pin && pin,
        };
        shareAPI.pin(rqData).then(
            (res) => {
                setIsOpen(false);
                setPatientInfo(res);
            },
            (error) => {
                console.log(error);
                setIsError(true);
                setIsOpen(false);
            }
        );
    };

    return (
        <Theme theme='g10'>
            <ComposedModal
                open={isOpen}
                size='xs'
                preventCloseOnClickOutside={true}
            >
                <ModalHeader>
                    <h1>Enter PIN number</h1>
                </ModalHeader>
                <ModalBody>
                    <p style={{ marginBottom: '1rem' }}>
                        Please type your email and pin number to see the
                        simulation.
                    </p>
                    <TextInput
                        data-modal-primary-focus
                        labelText='Email'
                        placeholder='Example@gmail.com'
                        style={{ marginBottom: '1rem' }}
                        type='email'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />

                    <TextInput
                        data-modal-primary-focus
                        labelText='PIN'
                        placeholder='type your PIN'
                        style={{ marginBottom: '1rem' }}
                        onChange={(e) => setPin(e.target.value)}
                    />
                </ModalBody>
                <ModalFooter primaryButtonText='Send' onClick={handleSend} />
            </ComposedModal>
        </Theme>
    );
};

export default PinModal;
