import { createGlobalStyle } from 'styled-components';

export const fonts = {
    logo: 'Proxima Nova',
    ibm: 'IBM Plex Sans',
    weight: {
        regular: 400,
        bold: 600,
    },
};

export const zIndex = {
    popupDialog: 100,
    modal: 2000,
    loading: 5000,
};

// need to redefine the colors after defined in figma
export const palette = {
    white: '#fff',
    text: '#3c3c3b',
    gray: {
        0: '#CACACA', // 사용중
        1: '#6f6f6f', // 사용중
        2: '#E5E5E5', // 사용중
        3: '#525252', // 사용중
        4: '#C6C6C6', // 사용중
        5: '#F4F4F4', // 사용중
        6: '#E0E0E0', // 사용중
        7: 'rgba(141, 141, 141, 0.24)',
        8: '#8d8d8d41',
        9: '#8D8D8D', // 사용중
        10: '#474747',
        11: '#393939',
        light: '#dfdfdf',
        middle: '#f7f7f7',
        border: '#c8c8c8',
        dark: '#979797',
    },

    blue: {
        0: '#D0E2FF', // 사용중
        1: '#002D9C', // 사용중
        2: '#78A9FF',
        3: '#0F62FE', // 사용중
        4: '#D0E2FF', // 사용중
        5: '#33B1FF', // 사용중
        6: '#4589FF', // 사용중
    },

    shadow: '#9fbcba',
    black: '#3c3c3b',

    primary: {
        gray: '#262626',
        blue: '#0F62FE', // 사용중
        linkBlue: '#78A9FF',
        black: '#161616', // 사용중
        warn: '#DA1E28', // 사용중
    },

    disabled: {
        backgroundColor: '#525252', // 사용중
        textColor: 'rgba(244, 244, 244, 0.25)',
    },
};

export const size = {
    header: 50,
    minHeight: 700,
    minWidth: 1100,
    modalWidth: 705,
};

export const GlobalStyles = createGlobalStyle`
`;
