import ViewerContainer from 'containers/viewer/ViewerContainer';
import React, { useEffect } from 'react';
import { usePathnameStoreMethods } from 'modules/stores/PathnameStore';

const Viewer = () => {
    const { setPathname } = usePathnameStoreMethods();

    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    return <ViewerContainer />;
};

export default Viewer;
