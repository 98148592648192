import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { VIEWER_STEP } from '../../modules/defines/values';
import useViewerStepStore from 'modules/stores/ViewerStep';
import { useConversionImgStoreMethods } from '../../modules/stores/ConversionImg';
import { Background } from '../common/Loading';

const Swticher = styled.div`
    width: 100%;
    border: 1px solid ${palette.blue[3]};
    border-radius: 5px;
    height: 35px;
    display: flex;
`;

const Switch = styled.button`
    background-color: ${(props) =>
        props.active === 'true'
            ? palette.blue[3]
            : props.progress === 'true'
            ? palette.blue[4]
            : 'transparent'};
    color: ${(props) =>
        props.active === 'true'
            ? `${palette.white}`
            : props.progress === 'true'
            ? palette.disabled.backgroundColor
            : palette.primary.black};
    width: 100%;
    text-align: start;
    padding-left: 20px;
    font-size: 14px;
    border: none;
    cursor: pointer;
    &:after {
        content: '|';
        display: block;
        color: ${palette.primary.black};
        float: right;
        margin-right: -8px;
    }

    &:last-child::after {
        content: '';
    }
    &:hover {
        background-color: ${palette.primary.blue};
        color: ${palette.white};
    }
    &:disabled {
        background-color: ${palette.gray[6]};
    }
`;
const Navigation = () => {
    const { setStep, setPrevious, setNextStep } = useViewerStepStore();
    const { step } = useViewerStepStore();
    const { setIsConversion } = useConversionImgStoreMethods();
    const [stepClick, setStepClick] = useState();

    useEffect(() => {
        if (typeof localStorage !== 'undefined') {
            const storedVal = JSON.parse(localStorage.getItem('step-state'));
            setStepClick(storedVal.state.step);
        }
    }, [step]);

    const handleState = (idx) => {
        if (idx === 3) {
            setIsConversion(true);
            return;
        }

        if (step === 3) {
            if (idx < step) {
                setPrevious(true);
                setNextStep(idx);
                return;
            }
        }

        setStepClick(idx);
        setStep(idx);
    };

    return (
        <Swticher>
            {VIEWER_STEP.map((d, i) => (
                <Switch
                    key={i}
                    active={(stepClick === i).toString()}
                    progress={(stepClick > i).toString()}
                    onClick={() => handleState(i)}
                    disabled={i > stepClick + 1}
                >
                    {d}
                </Switch>
            ))}
        </Swticher>
    );
};

export default Navigation;
