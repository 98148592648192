import React from 'react';

const Eye = ({ type }) => {
    if (type) {
        return (
            <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M12 6.24245C16.1345 6.24245 19.8218 8.4333 21.6218 11.8996C19.8218 15.3659 16.1345 17.5567 12 17.5567C7.86546 17.5567 4.17818 15.3659 2.37818 11.8996C4.17818 8.4333 7.86546 6.24245 12 6.24245ZM12 4.1853C6.54545 4.1853 1.88727 7.38416 0 11.8996C1.88727 16.415 6.54545 19.6139 12 19.6139C17.4545 19.6139 22.1127 16.415 24 11.8996C22.1127 7.38416 17.4545 4.1853 12 4.1853ZM12 9.32816C13.5055 9.32816 14.7273 10.4802 14.7273 11.8996C14.7273 13.319 13.5055 14.471 12 14.471C10.4945 14.471 9.27273 13.319 9.27273 11.8996C9.27273 10.4802 10.4945 9.32816 12 9.32816ZM12 7.27102C9.29455 7.27102 7.09091 9.34873 7.09091 11.8996C7.09091 14.4504 9.29455 16.5282 12 16.5282C14.7055 16.5282 16.9091 14.4504 16.9091 11.8996C16.9091 9.34873 14.7055 7.27102 12 7.27102Z'
                    fill='#0F62FE'
                />
            </svg>
        );
    } else {
        return (
            <svg
                width='23'
                height='24'
                viewBox='0 0 23 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
            >
                <path
                    d='M20.1909 9.25732C20.8152 9.84018 21.3409 10.4059 21.7352 10.8516C22.0076 11.1666 22.1584 11.5759 22.1584 12.0002C22.1584 12.4245 22.0076 12.8338 21.7352 13.1488C20.0102 15.0859 16.0837 18.8573 11.5002 18.8573H10.843'
                    stroke='#C6C6C6'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M6.35792 17.3659C4.45489 16.2538 2.73538 14.8299 1.26506 13.1488C0.992609 12.8338 0.841797 12.4245 0.841797 12.0002C0.841797 11.5759 0.992609 11.1666 1.26506 10.8516C2.99006 8.91449 6.91649 5.14307 11.5001 5.14307C13.3068 5.18247 15.0751 5.69534 16.6422 6.63449'
                    stroke='#C6C6C6'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M20.5358 2.57153L2.46436 21.4287'
                    stroke='#C6C6C6'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M9.18364 14.4172C8.5666 13.7772 8.21802 12.908 8.21436 12.0001C8.21436 11.0908 8.56053 10.2187 9.17672 9.57574C9.79291 8.93276 10.6286 8.57153 11.5001 8.57153C12.3701 8.57536 13.2031 8.9391 13.8165 9.58296'
                    stroke='#C6C6C6'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
                <path
                    d='M14.3585 13.7144C14.0661 14.236 13.6467 14.6677 13.1428 14.9658'
                    stroke='#C6C6C6'
                    strokeWidth='1.5'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                />
            </svg>
        );
    }
};

export default Eye;
