import React, { useEffect, useMemo, useState } from 'react';
import {
    ComposedModal,
    ModalHeader,
    ModalFooter,
    Button,
    ModalBody,
    GlobalTheme,
    Theme,
} from '@carbon/react';
import HandlePatient from 'components/common/HandlePatient';
import { styled } from 'styled-components';
import { palette } from 'modules/defines/styles';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    CREATE_DSD_CHART,
    CREATE_DSD_PATIENT,
    DELETE_DDHAIM_PATIENT,
} from 'modules/queries/chart';
import { useCookies } from 'react-cookie';
import {
    GET_ALL_PATIENT_LIST_OF_DOCTOR,
    DELETE_DSD_PATIENT,
} from 'modules/queries/chart';
import { getDateFormat } from 'modules/utils/functions';
import { viewerAPI } from 'modules/api/viewer';
import { paths } from 'modules/defines/paths';
import { useNavigate } from 'react-router-dom';
import { DELETE_DSD_CHART } from '../../../modules/queries/chart';

const AddNewPatientModal = ({
    open,
    closeModal,
    imgChange,
    src,
    onDelete,
    imgFile,
    userForm,
    setUserForm,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [key, setKey] = useState(false);
    const [cookies] = useCookies();
    const navigate = useNavigate();

    const [deletePatient] = useMutation(DELETE_DSD_PATIENT);
    const [deleteChart] = useMutation(DELETE_DSD_CHART);

    // false로 바꿔서 해야함.
    const [warn, setWarn] = useState({
        originalPhoto: false,
        patientName: false,
        birthDate: false,
        uploadDate: false,
        gender: false,
    });

    useEffect(() => {
        setUserForm({
            strapId: userForm.strapId,
            id: '',
            patientName: '',
            birthDate: '',
            uploadDate: '',
            gender: '',
        });
    }, []);

    useEffect(() => {
        if (src.original) {
            setWarn({ ...warn, originalPhoto: false });
        } else if (!src.original && warn.originalPhoto) {
            setWarn({ ...warn, originalPhoto: false });
        }
    }, [src]);

    const initState = () => {
        setIsFocused(false);
        setWarn({
            originalPhoto: false,
            patientName: false,
            birthDate: false,
            uploadDate: false,
            gender: false,
        });
        setUserForm({
            id: '',
            patientName: '',
            birthDate: '',
            uploadDate: '',
            gender: '',
        });
    };

    const handleChangeInput = (type, e) => {
        if (type === 'birthDate' || type === 'uploadDate') {
            setUserForm((prev) => ({ ...prev, [type]: e[0] }));
        } else {
            setUserForm((prev) => ({ ...prev, [type]: e }));
        }
        setWarn({ ...warn, [type]: false });
    };

    const [getAllPatientListOfDoctor] = useLazyQuery(
        GET_ALL_PATIENT_LIST_OF_DOCTOR
    );

    const [createPatient] = useMutation(CREATE_DSD_PATIENT);
    const [createChart] = useMutation(CREATE_DSD_CHART);

    const handleSave = (type) => {
        // 에러처리
        const regex = /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣]+$/;
        let basket = warn;

        if (!src.original) {
            basket.originalPhoto = true;
        }
        if (!userForm.patientName) {
            basket.patientName = 1;
        } else if (!regex.test(userForm.patientName)) {
            basket.patientName = 2;
        }
        if (!userForm.birthDate) {
            basket.birthDate = true;
        }
        if (!userForm.uploadDate) {
            basket.uploadDate = true;
        }
        if (!userForm.gender) {
            basket.gender = true;
        }
        setWarn(basket);
        setKey(!key);

        // 저장
        if (
            basket.originalPhoto === false &&
            basket.patientName === false &&
            basket.birthDate === false &&
            basket.uploadDate === false &&
            basket.gender === false
        ) {
            getAllPatientListOfDoctor({
                variables: {
                    filters: {
                        ddhaim_user: {
                            id: {
                                eq: cookies.i.user_info.id,
                            },
                        },
                        ddhaim_charts: {
                            ddhaim_chart_data: {
                                image_type_cd: {
                                    in: ['00729', '00730'],
                                },
                                deletedAt: {
                                    null: true,
                                },
                            },
                        },
                    },
                    ddhaimChartDataFilters2: {
                        deletedAt: {
                            null: true,
                        },
                    },
                },
                onCompleted: (response) => {
                    createPatient({
                        variables: {
                            data: {
                                first_name: userForm.patientName,
                                gender:
                                    userForm.gender === 'Female'
                                        ? Boolean(true)
                                        : Boolean(false),
                                birth_info: getDateFormat(
                                    new Date(userForm.birthDate)
                                ),
                                ddhaim_user: cookies.i.user_info.id,
                                patient_id: (
                                    response.ddhaimPatients.meta.pagination
                                        .total + 1
                                ).toString(),
                            },
                        },
                        onCompleted: (response) => {
                            const patientId =
                                response.createDdhaimPatient.data.id;
                            const uuid =
                                response.createDdhaimPatient.data.attributes
                                    .uuid;
                            createChart({
                                variables: {
                                    data: {
                                        ddhaim_patient: patientId && patientId,
                                    },
                                },
                                refetchQueries: [
                                    { query: GET_ALL_PATIENT_LIST_OF_DOCTOR },
                                ],
                                onCompleted: (response) => {
                                    const newChartId =
                                        response.createDdhaimChart.data.id;
                                    const rqData = {
                                        chart_id: newChartId,
                                        image_type_cd: '00729',
                                        original_name: `${newChartId}_00729.jpg`,
                                        shoot_date: getDateFormat(
                                            new Date(userForm.uploadDate)
                                        ),
                                    };
                                    viewerAPI
                                        .fileUpload(
                                            rqData,
                                            imgFile.original,
                                            cookies
                                        )
                                        .then(
                                            (response) => {
                                                console.log(response);
                                                closeModal();
                                                initState();

                                                if (type === 'design') {
                                                    navigate(
                                                        paths.viewer.replace(
                                                            ':id',
                                                            uuid
                                                        )
                                                    );
                                                }
                                            },
                                            (error) => {
                                                console.log(
                                                    'file upload error',
                                                    error
                                                );
                                                alert('try again');
                                                // delete chart
                                                deleteChart({
                                                    deleteDdhaimChartId:
                                                        newChartId,
                                                });
                                            }
                                        );
                                },
                                onError: (error) => {
                                    console.log('create chart error', error);
                                    alert('try again');
                                    // delete patient
                                    deletePatient({
                                        deleteDdhaimPatientId: patientId,
                                    });
                                },
                            });
                        },
                        onError: (error) => {
                            console.log('create patient error', error);
                        },
                    });
                },
                onError: (error) => {
                    console.log(error);
                },
            });
        }
    };

    return (
        <Theme theme='g10'>
            <ComposedModal
                open={open}
                size='md'
                onClose={() => {
                    initState();
                    closeModal();
                }}
                className='addPatientModal'
            >
                <ModalHeader title='신규 환자 등록' />
                <ModalBody>
                    <HandlePatient
                        type='addPatient'
                        isFocused={isFocused}
                        setIsFocused={setIsFocused}
                        imgChange={imgChange}
                        textChange={handleChangeInput}
                        src={src}
                        onDelete={onDelete}
                        warn={warn}
                        userForm={userForm}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        kind='secondary'
                        onClick={() => handleSave('design')}
                    >
                        스마일 디자인
                    </Button>
                    <Button kind='primary' onClick={() => handleSave()}>
                        저장
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </Theme>
    );
};

export default AddNewPatientModal;
