import Left from 'icons/Left';
import Right from 'icons/Right';
import { palette } from 'modules/defines/styles';
import React from 'react';
import { styled } from 'styled-components';

const GridRow = styled.div`
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    height: calc(100% / 12);
    margin-top: auto;
    align-items: center;
    border-top: 1px solid ${palette.gray[2]};
`;

const PagenationDivision = styled.div`
    display: flex;
    aspect-ratio: ${(props) => props.aspectRatio && props.aspectRatio};
    justify-content: space-between;
    align-items: center;
    background-color: inherit;
    height: 100%;
    border: none;
    outline: none;
    white-space: nowrap;
    margin-left: ${(props) => props.marginleft && props.marginleft};
    margin-right: ${(props) => props.marginRight && props.marginRight};
    padding: ${(props) => (props.padding ? props.padding : '15px 8px')};
    /* cursor: pointer; */
`;

const PageNationContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
    width: 14%;
    height: 100%;
    margin-left: auto;
    /* background-color: white; */
`;

const SquareButton = styled.button`
    display: flex;
    aspect-ratio: 1;
    justify-content: center;
    align-items: center;
    background-color: ${(props) =>
        props.backgroundcolor ? props.backgroundcolor : 'inherit'};
    color: ${(props) => (props.color ? props.color : palette.white)};
    height: ${(props) => (props.height ? props.height : '100%')};
    margin-left: ${(props) => (props.marginleft ? props.marginleft : '')};
    font-size: ${(props) => (props.fontsize ? props.fontsize : '14px')};
    border: none;
    outline: none;
    cursor: pointer;
    &:hover:not(:disabled) {
        background-color: ${(props) =>
            props.hovercolor ? props.hovercolor : ''};
    }
    &:disabled {
        pointer-events: none;
    }
`;

const PatientListFooter = ({
    patientList,
    handlePageClick,
    currentPage,
    totalPages,
}) => {
    return (
        <GridRow>
            <PageNationContainer>
                <PagenationDivision
                    aspectRatio='1'
                    marginleft='8px'
                    style={{ justifyContent: 'flex-end', paddingRight: '0' }}
                >
                    {currentPage}
                </PagenationDivision>
                <PagenationDivision marginRight='16px'>
                    of {totalPages === 0 ? 1 : totalPages} Page(s)
                </PagenationDivision>
            </PageNationContainer>
            <SquareButton
                hovercolor='rgba(141, 141, 141, 0.16)'
                disabled={
                    !patientList || patientList.length === 0 || currentPage <= 1
                }
                onClick={() => handlePageClick(-1)}
            >
                <Left
                    color={
                        (!patientList ||
                            patientList.length === 0 ||
                            currentPage <= 1) &&
                        'rgba(22, 22, 22, 0.25)'
                    }
                />
            </SquareButton>
            <SquareButton
                hovercolor='rgba(141, 141, 141, 0.16)'
                disabled={
                    !patientList ||
                    patientList.length === 0 ||
                    currentPage >= totalPages
                }
                onClick={() => handlePageClick(1)}
            >
                <Right
                    color={
                        (!patientList ||
                            patientList.length === 0 ||
                            currentPage >= totalPages) &&
                        'rgba(22, 22, 22, 0.25)'
                    }
                />
            </SquareButton>
        </GridRow>
    );
};

export default PatientListFooter;
