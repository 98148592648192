import { fonts, palette, size, zIndex } from 'modules/defines/styles';
import useViewerStepStore from 'modules/stores/ViewerStep';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { useConversionImgStoreMethods } from '../../modules/stores/ConversionImg';

// const ModalOverlay = styled.div`
//     position: fixed;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(0, 0, 0, 0.5);
//     z-index: ${zIndex.modal};
//     pointer-events: 'none';
// `;

export const ModalLayout = styled.div`
    width: 320px;
    z-index: ${zIndex.modal};
    background-color: ${palette.gray[5]};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.3);
`;

const ModalTitle = styled.div`
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: ${palette.primary.black};
    height: 60px;
    display: flex;
    align-items: center;
    padding: 16px;
    justify-content: space-between;
`;

export const ModalText = styled.div`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${palette.gray[0]};
    height: 60px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    justify-content: space-between;
`;

const ButtonWrapper = styled.div`
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;
`;

const RectButton = styled.button`
    width: 160px;
    height: 100%;
    background-color: ${(props) =>
        props.type ? palette.primary.gray : palette.primary.blue};
    color: ${palette.white};
    text-align: start;
    padding-left: 20px;
    & + & {
        margin-left: 1px;
    }
`;

export const ContentWrapper = styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    padding-bottom: 15px;
    padding-left: 16px;
    padding-right: 16px;
    span {
        font-size: 14px;
    }
    .cds--slider {
        min-width: 10.5rem;
    }
    .cds--text-input {
        border: none;
        border-bottom: none;
    }
    .cds--slider__range-label {
        visibility: hidden;
        width: 2px;
    }
`;

export const ModalPosition = styled.div`
    position: fixed;
    ${(props) => (props.type === 'right' ? 'right: 2%' : 'left: 2%')};
    top: 7%;
`;

const Modal = ({
    primaryText,
    secondaryText,
    title,
    onClick,
    children,
    idx,
    setIsShareModal,
}) => {
    const { setIsConversion } = useConversionImgStoreMethods();
    const { setStep, setPrevious, setNextStep } = useViewerStepStore();

    const handleClick = () => {
        if (idx === 2) setIsConversion(true);
        else if (idx === 3) setIsShareModal(true);
        else setStep(idx + 1);
    };

    const handlePrevious = () => {
        if (idx === 3) {
            setPrevious(true);
            setNextStep(2);
        } else setStep(idx - 1);
    };
    return (
        <>
            {/* <ModalOverlay /> */}
            <ModalLayout>
                <ModalTitle>{title}</ModalTitle>
                {children}
                <ButtonWrapper>
                    {secondaryText && (
                        <RectButton onClick={handlePrevious} type='secondary'>
                            {secondaryText}
                        </RectButton>
                    )}
                    <RectButton onClick={handleClick}>
                        {primaryText ? primaryText : 'Next'}
                    </RectButton>
                </ButtonWrapper>
            </ModalLayout>
        </>
    );
};

export default Modal;
