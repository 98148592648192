import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.scss';
import { render } from 'react-dom';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    from,
    createHttpLink,
} from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Cookies } from 'react-cookie';
import { axiosRefreshToken } from 'modules/api/http';
import { paths } from './modules/defines/paths';

const root = ReactDOM.createRoot(document.getElementById('root'));

const downCookies = new Cookies();
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_APPOLO_DEV,
});

const authLink = setContext((operation, { headers }) => {
    const cookies = downCookies.get('token');

    return {
        headers: {
            ...headers,
            authorization: cookies ? `bearer ${cookies}` : '',
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) =>
            console.log(
                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
            )
        );
    }

    if (networkError) {
        console.log(`[Network error]: ${networkError}`);
        alert('Network Error');
    }
});

const retryLink = new RetryLink({
    delay: {
        initial: 1000,
        max: 2000,
        jitter: true,
    },
    attempts: {
        max: 3,
        retryIf: (error, operation) => {
            const refreshToken = downCookies.get('refreshToken');
            if (error && error.statusCode === 401 && refreshToken) {
                return new Promise((resolve, reject) => {
                    axiosRefreshToken
                        .post('/api/refresh-token', {
                            refreshToken,
                        })
                        .then((response) => {
                            if (response.status === 200) {
                                downCookies.remove('token', { path: '/' });
                                downCookies.remove('refreshToken', {
                                    path: '/',
                                });
                                downCookies.set(
                                    'token',
                                    response.data.data.token
                                );
                                downCookies.set(
                                    'refreshToken',
                                    response.data.data.refresh_token
                                );

                                operation.setContext({
                                    headers: {
                                        ...operation.getContext().headers,
                                        Authorization: `Bearer ${response.data.data.token}`,
                                    },
                                });
                                resolve(true);
                            } else {
                                reject();
                            }
                        })
                        .catch((error) => {
                            console.log(error);

                            // exit to home
                            downCookies.remove('token', { path: '/' });
                            downCookies.remove('refreshToken', { path: '/' });
                            downCookies.remove('i', { path: '/' });

                            window.location.replace(paths.home);
                        });
                });
            }
            return !!error;
        },
    },
});

const appLink = from([errorLink, authLink.concat(retryLink).concat(httpLink)]);

const client = new ApolloClient({
    link: appLink,
    cache: new InMemoryCache(),
    defaultOptions: {
        watchQuery: {
            fetchPolicy: 'network-only',
        },
    },
});

root.render(
    <React.StrictMode>
        <ApolloProvider client={client}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
