import React, { useEffect, useState, forwardRef } from 'react';
import Modal, { ContentWrapper, ModalPosition } from 'components/common/Modal';
import styled from 'styled-components';
import PrepareImg from 'assets/preparation.svg';
import { Button, Slider } from '@carbon/react';
import * as d3 from 'd3';
import { palette } from 'modules/defines/styles';
import useAIresultStore from 'modules/stores/AIResult';
import useLibraryInfoStore from 'modules/stores/LibraryInfo';

const ImgWrapper = styled.div`
    display: flex;
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 16px;
`;

const AlignButton = styled.button`
    width: 100%;
    color: white;
    background-color: ${palette.gray[11]};
    min-height: 3rem;
    font-size: 14px;

    &:hover {
        background-color: ${palette.gray[10]};
    }
`;

const PrepareContainer = ({
    setRotationAngle,
    rotationAngle,
    setDegree,
    degree,
}) => {
    const { lipPoints, setLipPoints, setEyePoints, eyePoints, xCoords } =
        useAIresultStore();

    const { setDesiredCoord, setImageWidth, setMdWidth, setMdCoord, selected } =
        useLibraryInfoStore();

    useEffect(() => {
        const avgX = (xCoords.smallestXCoord[0] + xCoords.largestXCoord[0]) / 2;
        const avgY = (xCoords.smallestXCoord[1] + xCoords.largestXCoord[1]) / 2;
        setImageWidth(
            xCoords.largestXCoord[0] - xCoords.smallestXCoord[0] - 50
        );
        setMdWidth(xCoords.largestXCoord[0] - xCoords.smallestXCoord[0] - 50);
        setDesiredCoord([avgX, avgY]);
        setMdCoord([avgX, avgY + 30]);
    }, [xCoords, degree]);

    useEffect(() => {
        d3.select('#face').selectAll('polygon').remove();
        d3.select('#face').selectAll('clipPath').remove();
        d3.select('#face').selectAll('defs').remove();
    }, []);

    // eyes
    useEffect(() => {
        d3.select('#eyes').selectAll('line').remove();
        d3.select('#eyes').selectAll('circle').remove();

        const empty = d3.select('#eyes').empty();

        if (empty) {
            d3.select('#face').append('g').attr('id', 'eyes');
        }

        d3.select(`#eyes`)
            .append('line')
            .attr('x1', eyePoints[0][0])
            .attr('y1', eyePoints[0][1])
            .attr('x2', eyePoints[1][0])
            .attr('y2', eyePoints[1][1])
            .attr('stroke', palette.white)
            .attr('opacity', 0.8);

        eyePoints.forEach((point, index) => {
            drawingCircle(point, index, eyePoints, 'eyes');
        });

        d3.select('#eyes').call(
            d3
                .drag()
                .on('start', (event) => dragstarted(event, eyePoints, 'eyes'))
        );
    }, [eyePoints]);

    // lip
    let dx;
    let dy;
    let startX;
    let startY;
    useEffect(() => {
        d3.select('#lip').selectAll('circle').remove();
        d3.select('#lip').selectAll('path').remove();
        d3.select('#lip').selectAll('polygon').remove();

        const lineGenerator = d3
            .line()
            .x((d) => d.x)
            .y((d) => d.y)
            .curve(d3.curveCatmullRom.alpha(0.1));

        const pathData = lipPoints.map((point) => ({
            x: parseFloat(point[0]),
            y: parseFloat(point[1]),
        }));
        const curvedPathData = [pathData[pathData.length - 1], ...pathData];

        const empty = d3.select('#lip').empty();

        if (empty) {
            d3.select('#face').append('g').attr('id', 'lip');
        }

        const drag = d3
            .drag()
            .on('start', groupDragStart)
            .on('drag', groupDragging)
            .on('end', groupDraggingEnd);

        d3.select('#lip')
            .append('polygon')
            .attr('id', 'mask')
            .attr('points', lipPoints)
            .attr('stroke', 'none')
            .attr('stroke-dasharray', 1)
            .attr('fill', '#fff')
            .attr('opacity', 0.3)
            .attr('cursor', 'pointer')
            .call(drag);

        d3.select('#lip')
            .append('path')
            .attr('id', `line`)
            .attr('d', lineGenerator(curvedPathData))
            .attr('fill', 'none')
            .attr('stroke', palette.blue[5]);

        lipPoints.forEach((point, index) => {
            drawingCircle(point, index, lipPoints, 'lip');
        });

        d3.select('#lip')
            .selectAll('circle')
            .call(
                d3
                    .drag()
                    .on('start', (event) =>
                        dragstarted(event, lipPoints, 'lip')
                    )
            );
    }, [lipPoints]);

    let dragging;
    // center line
    useEffect(() => {
        if (dragging === true) return;
        d3.select('#face').selectAll('image').remove();
        d3.select('#centerline').selectAll('line').remove();

        const exist = !d3.select('#centerline').empty();

        if (!exist) {
            d3.select('#face').append('g').attr('id', 'centerline');
        }

        const point1 = {
            x: eyePoints[0][0] + (eyePoints[1][0] - eyePoints[0][0]) / 2,
            y:
                eyePoints[1][1] +
                Math.abs(eyePoints[1][1] - eyePoints[0][1]) / 2,
        };

        const eyeSlope =
            (eyePoints[1][1] - eyePoints[0][1]) /
            (eyePoints[1][0] - eyePoints[0][0]);
        const centerSlope = -1 / eyeSlope;

        if (eyeSlope === 0) {
            d3.select('#centerline')
                .append('line')
                .attr('x1', point1.x)
                .attr('y1', 0)
                .attr('x2', point1.x)
                .attr('y2', window.innerHeight)
                .attr('stroke', 'white')
                .attr('fill', 'none')
                .attr('opacity', 0.7);
        } else {
            const perpendicularIntercept = point1.y - centerSlope * point1.x;

            const lineX = [0, 10000];
            const lineY = lineX.map(
                (x) => centerSlope * x + perpendicularIntercept
            );

            d3.select('#centerline')
                .append('line')
                .attr('x1', lineX[0])
                .attr('y1', lineY[0])
                .attr('x2', lineX[1])
                .attr('y2', lineY[1])
                .attr('stroke', 'white')
                .attr('fill', 'none')
                .attr('opacity', 0.7);
        }
    }, [eyePoints]);

    function dragstarted(event, list, type) {
        dragging = true;
        const circle = d3.select(this);
        circle.classed('draggable', true);
        event.on('drag', dragged).on('end', ended);

        const startX = event.x;
        const startY = event.y;

        const idx = Number(event.sourceEvent.target.id.split('e')[1]);

        function dragged(event) {
            const newArr = [];
            list.map((d, i) => {
                if (i === idx) {
                    newArr.push([event.x, event.y]);
                } else newArr.push(d);
            });
            if (type === 'lip') {
                setLipPoints(newArr);
                localStorage.setItem('lipArea', JSON.stringify(newArr));
            } else if (type === 'eyes') {
                setEyePoints(newArr);
                localStorage.setItem('eyeTracking', JSON.stringify(newArr));
            }
            // else if (type === 'nose') setNosePoints(newArr);
        }

        function ended(event) {
            circle.classed('draggable', false);
            if (type === 'eyes') {
                const deltaX = event.x - startX;
                const deltaY = event.y - startY;
                const angle = Math.atan2(deltaY, deltaX) * 180;
                const newRotationAngle = rotationAngle + angle;
                setRotationAngle(newRotationAngle);
            }
            dragging = false;
        }
    }

    function drawingCircle(point, index, list, type) {
        d3.select(`#${type}`)
            .append('circle')
            .attr('id', `circle${index}`)
            .attr('cx', point[0])
            .attr('cy', point[1])
            .attr('r', type === 'lip' ? 2 : 7)
            .attr('stroke', palette.blue[5])
            .attr('cursor', 'pointer')
            .attr('fill', palette.blue[5]);
    }

    function groupDragStart(event) {
        startX = event.x;
        startY = event.y;
    }

    function groupDragging(event) {
        dx = event.x - startX;
        dy = event.y - startY;
        const arr = [];
        lipPoints.map((point) => {
            arr.push([point[0] + dx, point[1] + dy]);
        });

        setLipPoints(arr);
        localStorage.setItem('lipArea', JSON.stringify(arr));
    }
    function groupDraggingEnd(event) {}

    const handleAdjustDegree = () => {
        const circle0 = d3.select('#circle0');
        const c1x = circle0.attr('cx');
        const c1y = circle0.attr('cy');

        const circle1 = d3.select('#circle1');
        const c2x = circle1.attr('cx');
        const c2y = circle1.attr('cy');

        const ang = calculateAngle(c1x, c1y, c2x, c2y);
        setDegree(-ang);
    };

    const calculateAngle = (a, b, c, d) => {
        const deltaX = c - a;
        const deltaY = d - b;
        const angleRad = Math.atan2(deltaY, deltaX);
        const angleDeg = (angleRad * 180) / Math.PI;
        return angleDeg;
    };

    return (
        <ModalPosition>
            <Modal title='Preparing' idx={0}>
                <ContentWrapper>
                    <span> Adjust the placement of the inner lip-line</span>
                    <ImgWrapper>
                        <img width='120px' src={PrepareImg} alt='' />
                    </ImgWrapper>

                    <AlignButton onClick={handleAdjustDegree}>
                        Align
                    </AlignButton>
                    {/* <Slider
                        min={0}
                        max={100}
                        noValidate
                        value={50}
                        hideTextInput
                        // onChange={handleChange}
                        labelText='Lip editing radius'
                    /> */}
                </ContentWrapper>
            </Modal>
        </ModalPosition>
    );
};
export default PrepareContainer;
