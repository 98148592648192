import React from 'react';
import { useLazyQuery, useMutation } from '@apollo/client';
import {
    ComposedModal,
    ModalHeader,
    ModalFooter,
    Button,
    ModalBody,
    GlobalTheme,
    Theme,
} from '@carbon/react';
import { DELETE_DSD_PATIENT } from 'modules/queries/chart';

const DeletePatientModal = ({ closeDelete, open, userForm }) => {
    const [deletePatient] = useMutation(DELETE_DSD_PATIENT);

    const handleDelete = () => {
        deletePatient({
            variables: {
                data: {
                    deletedAt: new Date().toISOString(),
                },
                updateDdhaimPatientId: userForm && userForm.strapiId,
            },
            onCompleted: (res) => {
                console.log(res);
                closeDelete();
            },
            onError: (e) => {
                console.log(e);
            },
        });
    };

    return (
        <ComposedModal open={open} size='xs' onClose={closeDelete}>
            <ModalHeader title='Delete Patient' />
            <ModalBody>
                <p style={{ marginBottom: '1rem' }}>
                    Do you really want to delete patient {userForm.patientName}
                    's charts?
                </p>
            </ModalBody>
            <ModalFooter>
                <Button kind='secondary' onClick={closeDelete}>
                    Cancel
                </Button>
                <Button kind='primary' onClick={handleDelete}>
                    Delete
                </Button>
            </ModalFooter>
        </ComposedModal>
    );
};

export default DeletePatientModal;
