import React from 'react';
import {
    ComposedModal,
    ModalHeader,
    ModalFooter,
    Button,
    ModalBody,
    GlobalTheme,
    Theme,
} from '@carbon/react';

const DeleteModal = ({ open, closeDelete, onDelete }) => {
    return (
        <ComposedModal open={open} size='xs' onClose={closeDelete}>
            <ModalHeader title='Delete' />
            <ModalBody>
                <p style={{ marginBottom: '1rem' }}>
                    Do you really want to delete the photo?
                </p>
            </ModalBody>
            <ModalFooter>
                <Button kind='secondary' onClick={closeDelete}>
                    Cancel
                </Button>
                <Button kind='primary' onClick={onDelete}>
                    Delete
                </Button>
            </ModalFooter>
        </ComposedModal>
    );
};

export default DeleteModal;
