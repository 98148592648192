import React from 'react';

const SideMenu = () => (
    <svg
        width='2'
        height='10'
        viewBox='0 0 2 10'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M1 2.00098C1.55228 2.00098 2 1.55326 2 1.00098C2 0.448692 1.55228 0.000976562 1 0.000976562C0.447715 0.000976562 0 0.448692 0 1.00098C0 1.55326 0.447715 2.00098 1 2.00098Z'
            fill='#161616'
        />
        <path
            d='M1 6.00098C1.55228 6.00098 2 5.55326 2 5.00098C2 4.44869 1.55228 4.00098 1 4.00098C0.447715 4.00098 0 4.44869 0 5.00098C0 5.55326 0.447715 6.00098 1 6.00098Z'
            fill='#161616'
        />
        <path
            d='M1 10.001C1.55228 10.001 2 9.55326 2 9.00098C2 8.44869 1.55228 8.00098 1 8.00098C0.447715 8.00098 0 8.44869 0 9.00098C0 9.55326 0.447715 10.001 1 10.001Z'
            fill='#161616'
        />
    </svg>
);

export default SideMenu;
