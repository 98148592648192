export const getDateFormat = (date) => {
    if (!date) return date;
    if (typeof date === 'string') return date.split('T')[0];
    // .replaceAll('-', '.')
    const y = date.getFullYear();
    const m =
        date.getMonth() + 1 < 10
            ? `0${date.getMonth() + 1}`
            : date.getMonth() + 1;
    const d = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    return `${y}-${m}-${d}`;
};

export const convertURLtoFile = async (url) => {
    const response = await fetch(url);
    const data = await response.blob();
    const ext = url.split('.').pop(); // url 구조에 맞게 수정할 것
    const filename = url.split('/').pop(); // url 구조에 맞게 수정할 것
    const metadata = { type: `image/${ext}` };

    return new File([data], filename, metadata);
};
