import React from 'react';

const Right = ({ color }) => (
    <svg
        width='5'
        height='8'
        viewBox='0 0 5 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M0 0.000976562L5 4.00098L0 8.00098V0.000976562Z'
            fill={color || '#161616'}
        />
    </svg>
);

export default Right;
