import React, { useEffect, useState } from 'react';
import Header from 'components/common/Header';
import { paths } from 'modules/defines/paths';
import { Download } from '@carbon/icons-react';

import Navigation from 'components/viewer/Navigation';
import styled from 'styled-components';
import Logout from 'components/icons/Logout';

import usePathnameStore from '../../modules/stores/PathnameStore';

const NaviWrapper = styled.div`
    width: 60%;
    position: absolute;
    left: 25%;
`;

const LogoutWrapper = styled.div`
    display: flex;

    a {
        text-decoration: none;
        height: 24px;
        font-size: 14px;
        color: black;
        margin-right: 5px;
    }
`;

const HeaderContainer = ({ setIsConfirm }) => {
    const { pathname } = usePathnameStore();

    const handleLogout = () => {
        setIsConfirm(true);
    };

    return (
        <Header logoLink={paths.chart}>
            {pathname.includes('viewer') && (
                <NaviWrapper>
                    <Navigation />
                </NaviWrapper>
            )}
            <LogoutWrapper>
                {!pathname.includes('share') && (
                    <a
                        href='docs/사용자매뉴얼-JUDY-202310.pdf'
                        download
                        rel='noopener noreferrer'
                    >
                        Manual
                        <Download width='24' height='20' />
                    </a>
                )}
                {!pathname.includes('share') && pathname !== `/` && (
                    <button
                        onClick={handleLogout}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        logout &nbsp;
                        <Logout />
                    </button>
                )}
            </LogoutWrapper>
        </Header>
    );
};

export default HeaderContainer;
