import React, { useEffect, useMemo, useState } from 'react';
import { palette } from 'modules/defines/styles';
import { styled } from 'styled-components';
import { useDropzone } from 'react-dropzone';
import TrashCan from 'components/icons/TrashCan';
import {
    DatePicker,
    DatePickerInput,
    TextInput,
    RadioButtonGroup,
    RadioButton,
} from '@carbon/react';
import Warning from 'components/icons/Warning';
import UploadFace from 'components/icons/UploadFace';
import ResultFace from 'components/icons/ResultFace';

const UploadContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 310px;
`;

const EditUploadWapper = styled.div`
    display: flex;
    justify-content: center;
    & > div + div {
        margin-left: 45px;
    }
`;

const ImageItemWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    /* margin-bottom: 8px; */
`;

const ImageArea = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 280px;
    aspect-ratio: 1;
    border: ${(props) =>
        props.src && props.src.original
            ? ''
            : props.warn
            ? '2px solid #da1e28'
            : '1px dashed #818595'};
    cursor: pointer;
`;

const ImageResultArea = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 280px;
    aspect-ratio: 1;
    border: ${(props) =>
        props.src && props.src.result
            ? ''
            : props.warn
            ? '2px solid #da1e28'
            : '1px dashed #818595'};
`;

const ImageIcon = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${(props) => (props.size === 'small' ? '10px' : '15px')};

    img {
        ${(props) =>
            props.size === 'small' &&
            `
      height: 55px;
    `}
    }
`;

const ImageAreaInner = styled.div`
    color: ${palette.blue[3]};
    font-size: 12px;
    text-align: center;
    padding: 0 25px;
    strong {
        color: ${palette.primary.blue};
    }
    span {
        display: block;
        &:last-child {
            margin-top: ${(props) => (props.size === 'small' ? '5px' : '9px')};
            font-size: 8px;
        }
    }
`;

const ImageWrapper = styled.div`
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: url(${(props) => props.src}) no-repeat 50% 50% / cover;
    border-radius: 5px;
`;

const DeleteButton = styled.span`
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
`;

const TextWrapper = styled.div`
    display: flex;
    justify-content: center;
`;

const focusedStyle = {
    border: `2px solid ${palette.blue[3]}`,
};
const acceptStyle = {
    background: 'rgba(43, 43, 43, 0.8)',
};
const rejectStyle = {
    background: 'rgb(251 56 54 / 20%)',
};

const HandlePatient = ({
    type,
    isFocused,
    setIsFocused,
    imgChange,
    textChange,
    src,
    onDelete,
    warn,
    handleWarn,
    setKey,
    key,
    onDeleteModal,
    userForm,
}) => {
    const [today, setToday] = useState('');

    const {
        getRootProps,
        getInputProps,
        // isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        accept: 'image/*',
        onDrop: (acceptedFiles) => {
            acceptedFiles.map((file) => {
                if (imgChange) imgChange(file, 'original');
                setIsFocused(false);
            });
        },
    });
    const dropStyle = useMemo(
        () => ({
            ...(isFocused ? focusedStyle : {}),
            ...(isDragAccept ? acceptStyle : {}),
            ...(isDragReject ? rejectStyle : {}),
        }),
        [isFocused, isDragAccept, isDragReject]
    );

    const getTodayDate = () => {
        let today = new Date();
        let dd = String(today.getDate()).padStart(2, '0');
        let mm = String(today.getMonth() + 1).padStart(2, '0');
        let yyyy = today.getFullYear();
        setToday(mm + '/' + dd + '/' + yyyy);
    };

    useEffect(() => {
        getTodayDate();
    }, []);

    return (
        <>
            {type === 'addPatient' ? (
                <UploadContainer>
                    <p
                        className='cds--label-description'
                        style={{ width: '280px', position: 'relative' }}
                    >
                        Upload smile photo
                        {warn.originalPhoto && (
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '0px',
                                    right: '0',
                                }}
                            >
                                <Warning />
                            </div>
                        )}
                    </p>
                    <ImageItemWrapper>
                        <ImageArea
                            src={src}
                            warn={warn.originalPhoto}
                            {...getRootProps({
                                style: dropStyle,
                                onFocus: () => setIsFocused(true),
                            })}
                            // onClick={(e) => textChange('originalPhoto', src)}
                        >
                            <input {...getInputProps()} type='file' />
                            {src && src.original ? (
                                <>
                                    <ImageWrapper src={src.original} />
                                    <DeleteButton
                                        onClick={(e) => {
                                            onDelete('original', e);
                                            setIsFocused(false);
                                        }}
                                    >
                                        <TrashCan />
                                    </DeleteButton>
                                </>
                            ) : (
                                <>
                                    <ImageIcon>
                                        <UploadFace />
                                    </ImageIcon>
                                    <ImageAreaInner>
                                        Drop your image here, or click to upload
                                    </ImageAreaInner>
                                </>
                            )}
                        </ImageArea>
                    </ImageItemWrapper>
                    {warn.originalPhoto && (
                        <div
                            style={{
                                width: '280px',
                                color: `${palette.primary.warn}`,
                            }}
                        >
                            Upload Smile photo
                        </div>
                    )}{' '}
                </UploadContainer>
            ) : (
                <EditUploadWapper>
                    <UploadContainer>
                        <p
                            className='cds--label-description'
                            style={{ width: '280px', position: 'relative' }}
                        >
                            Upload smile photo
                            {warn.originalPhoto && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '0px',
                                        right: '0',
                                    }}
                                >
                                    <Warning />
                                </div>
                            )}
                        </p>
                        <ImageItemWrapper>
                            <ImageArea
                                src={src}
                                warn={warn.originalPhoto}
                                {...getRootProps({
                                    style: dropStyle,
                                    onFocus: () => setIsFocused(true),
                                })}
                                // onClick={(e) => textChange('originalPhoto', src)}
                            >
                                <input {...getInputProps()} type='file' />
                                {src && src.original ? (
                                    <>
                                        <ImageWrapper src={src.original} />
                                        <DeleteButton
                                            onClick={(e) => {
                                                onDelete('original', e);
                                                setIsFocused(false);
                                            }}
                                        >
                                            <TrashCan />
                                        </DeleteButton>
                                    </>
                                ) : (
                                    <>
                                        <ImageIcon>
                                            <UploadFace />
                                        </ImageIcon>
                                        <ImageAreaInner>
                                            Drop your image here, or click to
                                            upload
                                        </ImageAreaInner>
                                    </>
                                )}
                            </ImageArea>
                        </ImageItemWrapper>
                        {warn.originalPhoto && (
                            <div
                                style={{
                                    width: '280px',
                                    color: `${palette.primary.warn}`,
                                }}
                            >
                                Input patient smile photo
                            </div>
                        )}
                    </UploadContainer>
                    <UploadContainer>
                        <p
                            className='cds--label-description'
                            style={{ width: '280px', position: 'relative' }}
                        >
                            Result smile photo
                        </p>
                        <ImageItemWrapper>
                            <ImageResultArea>
                                {src && src.result ? (
                                    <>
                                        <ImageWrapper src={src.result} />
                                        <DeleteButton
                                            onClick={(e) => {
                                                onDelete('result', e);
                                                setIsFocused(false);
                                            }}
                                        >
                                            <TrashCan />
                                        </DeleteButton>
                                    </>
                                ) : (
                                    <>
                                        <ImageIcon>
                                            <ResultFace />
                                        </ImageIcon>
                                        <ImageAreaInner>
                                            Please work with smile design to see
                                            results of a virtual treatment
                                        </ImageAreaInner>
                                    </>
                                )}
                            </ImageResultArea>
                        </ImageItemWrapper>
                    </UploadContainer>
                </EditUploadWapper>
            )}
            <TextWrapper style={{ marginTop: '24px' }}>
                <div
                    style={{
                        width: '300px',
                        marginRight: '24px',
                        position: 'relative',
                    }}
                >
                    <TextInput
                        className='input-test-class'
                        labelText='Patient name'
                        playgroundWidth='300'
                        placeholder='Input text'
                        type='text'
                        invalid={
                            warn.patientName === 1 || warn.patientName === 2
                        }
                        invalidText={
                            warn.patientName === 1
                                ? 'Input patient name'
                                : warn.patientName === 2
                                ? '한글과 영어만 입력해주세요.'
                                : ''
                        }
                        onChange={(e) =>
                            textChange('patientName', e.target.value)
                        }
                        value={
                            userForm && userForm.patientName
                                ? userForm.patientName
                                : ''
                        }
                    />
                </div>
                <div style={{ position: 'relative' }}>
                    <DatePicker
                        allowInput={false}
                        datePickerType='single'
                        maxDate={today}
                        onChange={(e) => textChange('birthDate', e)}
                        value={
                            userForm && userForm.birthDate
                                ? new Date(userForm.birthDate)
                                : ''
                        }
                    >
                        <DatePickerInput
                            placeholder='mm/dd/yyyy'
                            labelText='Birth Date'
                            invalid={warn.birthDate}
                            invalidText='Input birth date'
                        />
                    </DatePicker>
                </div>
            </TextWrapper>
            <TextWrapper style={{ marginTop: '24px' }}>
                <div
                    style={{
                        marginRight: '24px',
                        width: '300px',
                        position: 'relative',
                    }}
                >
                    <DatePicker
                        datePickerType='single'
                        onChange={(e) => textChange('uploadDate', e)}
                        value={
                            userForm && userForm.uploadDate
                                ? new Date(userForm.uploadDate)
                                : ''
                        }
                        allowInput={false}
                        readOnly={type === 'edit'}
                    >
                        <DatePickerInput
                            placeholder='mm/dd/yyyy'
                            labelText='Shoot Date'
                            invalid={warn.uploadDate}
                            invalidText='Input shoot date'
                        />
                    </DatePicker>
                </div>
                <div style={{ width: '300px', position: 'relative' }}>
                    <RadioButtonGroup
                        legendText='Gender'
                        defaultSelected={userForm.gender && userForm.gender}
                        invalid={warn.gender}
                        invalidText={`Please choose the patient's gender`}
                    >
                        <RadioButton
                            labelText='Male'
                            onClick={() => textChange('gender', 'Male')}
                            value={'Male'}
                            checked={
                                userForm && userForm.gender === false
                                    ? true
                                    : false
                            }
                        />
                        <RadioButton
                            labelText='Female'
                            onClick={() => textChange('gender', 'Female')}
                            value={'Female'}
                            checked={
                                userForm && userForm.gender === true
                                    ? true
                                    : false
                            }
                        />
                    </RadioButtonGroup>
                </div>
            </TextWrapper>
            <div style={{ width: '100%', height: '48px' }}></div>
        </>
    );
};

export default HandlePatient;
