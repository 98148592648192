import React from 'react';

const Logout = () => (
    <svg
        width='24'
        height='24'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M3.48521 22L11.3698 22L11.3698 20L4.47079 20L4.47082 4L11.3699 4L11.3699 2L3.48524 2C3.22385 2 2.97316 2.10536 2.78833 2.29289C2.6035 2.48043 2.49966 2.73478 2.49966 3L2.49963 21C2.49963 21.2652 2.60347 21.5196 2.7883 21.7071C2.97313 21.8946 3.22382 22 3.48521 22Z'
            fill='black'
        />
        <path
            d='M18.4996 13.0123L8.86418 13.0122L8.86418 11.0122L18.4996 11.0123L16.2397 8.71933L17.6333 7.30533L21.5756 11.3053C21.7604 11.4929 21.8642 11.7472 21.8642 12.0123C21.8642 12.2775 21.7604 12.5318 21.5756 12.7193L17.6333 16.7193L16.2397 15.3053L18.4996 13.0123Z'
            fill='black'
        />
    </svg>
);

export default Logout;
