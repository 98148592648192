import React, { useEffect, useState } from 'react';
import { palette } from 'modules/defines/styles';
import PinModal from 'components/Chart/modal/PinModal';
import ShareSimulation from '../layouts/ShareSimulation';
import DefaultModal from '../components/Chart/modal/DefaultModal';

const ShareContainer = () => {
    const [userForm, setUserForm] = useState({});
    const [isOpen, setIsOpen] = useState(true);
    const [patientInfo, setPatientInfo] = useState();
    const [isError, setIsError] = useState();

    useEffect(() => {
        if (patientInfo) {
            setUserForm({
                patientName: patientInfo.first_name,
                gender: patientInfo.gender,
                birthDate: patientInfo.birth_info,
                path: patientInfo.path.data,
            });
        }
    }, [patientInfo]);

    const handleErrorClose = () => {
        setIsError(false);
        setIsOpen(true);
    };

    return (
        <div style={{ backgroundColor: palette.gray[2] }}>
            <PinModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                setPatientInfo={setPatientInfo}
                setIsError={setIsError}
            />
            {!isOpen && !isError && userForm && (
                <ShareSimulation userForm={userForm} />
            )}
            {isError && (
                <DefaultModal
                    title='Error'
                    text='No matching information found'
                    open={isError}
                    onClose={handleErrorClose}
                />
            )}
        </div>
    );
};

export default ShareContainer;
