import React from 'react';
import {
    ComposedModal,
    ModalHeader,
    ModalFooter,
    Button,
    ModalBody,
} from '@carbon/react';

const DefaultModal = ({ onClose, open, text, title }) => {
    return (
        <ComposedModal open={open} size='xs' onClose={onClose}>
            <ModalHeader title={title} />
            <ModalBody>
                <p style={{ marginBottom: '1rem' }}>{text}</p>
            </ModalBody>
            <ModalFooter>
                <Button kind='primary' onClick={onClose}>
                    Confirm
                </Button>
            </ModalFooter>
        </ComposedModal>
    );
};

export default DefaultModal;
