import React, { useEffect, useState } from 'react';
import ChartLayout from 'layouts/ChartLayout';
import { palette } from 'modules/defines/styles';
import { styled } from 'styled-components';
import { Button, Search } from '@carbon/react';
import SearchIcon from 'icons/SearchIcon';
import CloseIcon from 'icons/CloseIcon';
import { ArrowsVertical, ArrowUp } from '@carbon/icons-react';
import PatientListHeader from './PatientListHeader';
import PatientListBody from './PatientListBody';
import PatientListFooter from './PatientListFooter';
import { useQuery, useLazyQuery } from '@apollo/client';
import { GET_ALL_PATIENT_LIST_OF_DOCTOR } from '../../modules/queries/chart';
import { useCookies } from 'react-cookie';

const PatientTitle = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 68px;
    padding-left: 16px;
    font-size: 20px;
`;
const PatientSearchContainer = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 48px;
`;

const PatientSearchInner = styled.div`
    display: flex;
    flex-direction: start;
    width: 400px;
    height: 100%;
    border-bottom: 1px solid ${palette.gray[1]};
    align-items: center;
    /* padding-right: 16px; */

    /* outline: ${(props) => props.settingDisabled && '2px solid white'}; */
    /* outline-offset: -2px; */
`;

const SearchIconBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: ${(props) => props.width && props.width};
    aspect-ratio: 1;
    &#deleteSearchInput > div {
        cursor: pointer;
    }
`;

const SearchInput = styled.input`
    display: flex;
    justify-content: center;
    width: 224px;
    height: 100%;
    font-size: 14px;
    border: none;
    outline: none;
    background: transparent;
    color: black;
    ::placeholder {
        color: ${palette.disabled.textColor};
    }
`;
const PatientListContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const boardHeaderList = [
    {
        width: '11%',
        label: 'ID',
        sortValue: 'patient_id',
        sortable: true,
    },
    {
        width: '13%',
        label: 'M/F',
        sortValue: 'gender',
        sortable: true,
    },
    {
        width: '18%',
        label: 'Birth Date',
        sortValue: 'birth_info',
        sortable: true,
    },
    {
        width: '18%',
        label: 'Name',
        sortValue: 'first_name',
        sortable: true,
    },
    { width: '20%', label: 'Edited' },
    {
        width: '20%',
        label: 'Uploaded',
        sortValue: 'createdAt',
        sortable: true,
    },
];

// const itemsPerPage = 10;
const ChartComponent = ({
    onDelete,
    onExport,
    onShare,
    onAddPatient,
    onEdit,
    patientList,
    setPatientList,
    setTotal,
    searchInput,
    setSearchInput,
    currentPage,
    sortingItem,
    sortingOrder,
    onSort,
    onPageClick,
    totalPages,
}) => {
    const [isSearch, setIsSearch] = useState(false);
    const [cookies] = useCookies();

    const handleSumbitSearch = (e) => {
        if (e.key === 'Enter' || e.type === 'click') {
            setIsSearch(true);
        }
    };

    const [getAllChartListOfPatientSearch] = useLazyQuery(
        GET_ALL_PATIENT_LIST_OF_DOCTOR
    );

    useEffect(() => {
        try {
            if (isSearch && searchInput !== '') {
                getAllChartListOfPatientSearch({
                    variables: {
                        filters: {
                            deletedAt: {
                                null: true,
                            },
                            first_name: {
                                contains: searchInput,
                            },
                            ddhaim_user: {
                                id: {
                                    eq: cookies.i.user_info.id,
                                },
                            },
                            ddhaim_charts: {
                                ddhaim_chart_data: {
                                    image_type_cd: {
                                        in: ['00729', '00730'],
                                    },
                                },
                            },
                        },
                        ddhaimChartDataFilters2: {
                            deletedAt: {
                                null: true,
                            },
                        },
                        sort: [`${sortingItem}:${sortingOrder}`],
                    },
                    onCompleted: (data) => {
                        console.log(data);
                        const listData2 = data.ddhaimPatients.data;
                        setPatientList(listData2);
                        setTotal(data.ddhaimPatients.meta.pagination.total);
                        setIsSearch(false);
                    },
                });
            }
        } catch (error) {
            console.log(error);
        }
    }, [searchInput, isSearch, currentPage]);

    const handleInit = () => {
        setIsSearch(false);
        setSearchInput('');
    };

    return (
        <ChartLayout>
            <PatientTitle>환자 차트</PatientTitle>
            <PatientSearchContainer>
                <PatientSearchInner>
                    <SearchIconBox>
                        <SearchIcon />
                    </SearchIconBox>
                    <SearchInput
                        type='text'
                        placeholder='Typing'
                        onChange={(e) => setSearchInput(e.target.value)}
                        value={searchInput}
                        onKeyDown={handleSumbitSearch}
                    />
                    <SearchIconBox id='deleteSearchInput'>
                        <div onClick={handleInit}>
                            <CloseIcon
                                color={searchInput.length !== 0 && 'black'}
                            />
                        </div>
                    </SearchIconBox>
                    <Button
                        className='searchButton'
                        disabled={searchInput.length !== 0 ? false : true}
                        onClick={() => setIsSearch(true)}
                    >
                        Search
                    </Button>
                </PatientSearchInner>
                <Button className='addNewPatientButton' onClick={onAddPatient}>
                    신규 환자 등록
                </Button>
            </PatientSearchContainer>
            <PatientListContainer>
                {/* // ! mouseUp 사용해서 아이콘 띄우기(header) */}
                <PatientListHeader
                    headerList={boardHeaderList}
                    onSort={onSort}
                />
                <PatientListBody
                    patientList={patientList}
                    onDelete={onDelete}
                    onShare={onShare}
                    onEdit={onEdit}
                />
                <PatientListFooter
                    patientList={patientList}
                    handlePageClick={onPageClick}
                    currentPage={currentPage}
                    totalPages={totalPages}
                />
            </PatientListContainer>
        </ChartLayout>
    );
};

export default ChartComponent;
