import Modal, { ContentWrapper, ModalPosition } from 'components/common/Modal';
import PatientInfo from 'components/viewer/PatientInfo';
import { palette } from 'modules/defines/styles';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { RowItem } from './SimulationContainer';
import * as d3 from 'd3';
import MxLibrary1 from 'assets/library_maxillary1.svg';
import MxLibrary2 from 'assets/library_maxillary2.svg';
import MdLibrary1 from 'assets/library_mandibular1.svg';
import Left from 'assets/left-arrow.svg';
import Top from 'assets/top-arrow.svg';
import Move from 'assets/movement-arrow.svg';
import Right from 'assets/right-arrow.svg';
import Bottom from 'assets/bottom-arrow.svg';
import useLibraryInfoStore from 'modules/stores/LibraryInfo';
import useAIresultStore from 'modules/stores/AIResult';
import useDesignViewStore from '../../modules/stores/DesignView';

const LibraryWrap = styled.div`
    width: 100%;
    height: 180px;
    background-color: ${palette.white};
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    overflow: scroll;
`;

const LibraryButton = styled.button`
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    text-align: start;
    padding: 7px 15px;
    background-color: ${(props) => props.selected && palette.gray[9]}33;
    ${(props) => props.selected && `border-left: 3px solid ${palette.blue[3]}`};

    &:hover {
        background-color: ${palette.gray[9]}1F;
    }

    &:active {
        background-color: ${palette.gray[9]}80;
    }
`;

const MxLibrary = [
    { label: 'Ovoid type', selected: false },
    { label: 'Square type', selected: false },
];

const MdLibrary = [{ label: 'Triangular type', selected: false }];

const SelectBtn = styled.button`
    background-color: ${(props) =>
        props.selected ? palette.blue[1] : palette.primary.blue};
    color: ${palette.white};
    width: 100%;
    height: 46px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    & + & {
        margin-left: 16px;
    }
`;

const AdjustmentContainer = ({ userForm, degree }) => {
    const { lipPoints } = useAIresultStore();
    const {
        selected,
        setSelected,
        mdLib,
        mxLib,
        mdWidth,
        mdHeight,
        setMdWidth,
        setMdHeight,
        setMandibularLib,
        setMaxilaryLib,
        setImageWidth,
        setImageHeight,
        imageWidth,
        imageHeight,
        desiredCoord,
        setDesiredCoord,
        mdCoord,
        setMdCoord,
    } = useLibraryInfoStore();
    const { designView } = useDesignViewStore();

    const clipPathId = 'clip-path';

    const handleSelect = (i) => {
        if (selected === 'maxillary') {
            if (mxLib === i) {
                setMaxilaryLib('');
            } else if (mxLib === '' || mxLib !== i) {
                setMaxilaryLib(i);
            }
        } else {
            if (mdLib === i) {
                setMandibularLib('');
            } else if (mdLib === '' || mdLib !== i) {
                setMandibularLib(i);
            }
        }
    };

    const selectLibrary = () => {
        switch (mxLib) {
            default:
                return MxLibrary1;
            case 1:
                return MxLibrary2;
        }
    };

    const selectMdLibrary = () => {
        switch (mdLib) {
            default:
                return MdLibrary1;
        }
    };

    useEffect(() => {
        d3.select('#face').selectAll('g').remove();
        d3.select('#face').selectAll('clipPath').remove();
        d3.select('#face').selectAll('polygon').remove();
        d3.select('#face').selectAll('defs').remove();
        d3.select('#lipSvg').selectAll('image').remove();
        d3.select('#lipSvg').selectAll('clipPath').remove();
        if (designView) {
            if (mxLib !== '' && mdLib !== '') {
                appendMandibular();
                appendMaxillary();
                appendArrow();
            } else if (mxLib !== '' && mdLib === '') {
                appendMaxillary();
                appendArrow();
            } else if (mxLib === '' && mdLib !== '') {
                appendMandibular();
                appendArrow();
            } else {
                d3.select('#lipSvg').selectAll('image').remove();
            }
        }
        const points = lipPoints.map((point) => point.join(',')).join(' ');
        d3.select('#face')
            .append('clipPath')
            .attr('id', clipPathId)
            .append('polygon')
            .attr('points', points)
            .attr('transform', `rotate(${degree})`)
            .style('transform-origin', 'center');

        d3.select('#image').attr('clip-path', `url(#${clipPathId})`);
        d3.select('#mdimage').attr('clip-path', `url(#${clipPathId})`);
    }, [
        mxLib,
        mdLib,
        imageWidth,
        imageHeight,
        mdWidth,
        mdHeight,
        desiredCoord,
        mdCoord,
        designView,
        degree,
        selected,
    ]);

    function appendArrow() {
        appendImage(
            'leftArrow',
            Left,
            15,
            15,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[0] - imageWidth / 2 - 15
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[0] - mdWidth / 2 - 15
                : -100,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[1]
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[1]
                : -100
        );
        appendImage(
            'rightArrow',
            Right,
            15,
            15,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[0] + imageWidth / 2
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[0] + mdWidth / 2
                : -100,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[1]
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[1]
                : -100
        );
        appendImage(
            'moveArrow',
            Move,
            30,
            30,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[0] - 15
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[0] - 15
                : -100,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[1] - 45
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[1] - 45
                : -100
        );
        appendImage(
            'topArrow',
            Top,
            15,
            15,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[0] - 7
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[0] - 7
                : -100,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[1] - 10
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[1] - 10
                : -100
        );
        appendImage(
            'bottomArrow',
            Bottom,
            15,
            15,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[0] - 7
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[0] - 7
                : -100,
            selected === 'maxillary' && mxLib !== ''
                ? desiredCoord[1] + imageHeight
                : selected === 'mandibular' && mdLib !== ''
                ? mdCoord[1] + mdHeight
                : -100
        );
    }

    function appendMaxillary() {
        appendImage(
            'image',
            selectLibrary(),
            imageWidth,
            imageHeight,
            desiredCoord[0] - imageWidth / 2,
            desiredCoord[1]
        );
    }

    function appendMandibular() {
        appendImage(
            'mdimage',
            selectMdLibrary(),
            mdWidth,
            mdHeight,
            mdCoord[0] - mdWidth / 2,
            mdCoord[1]
        );
    }

    function appendImage(id, href, width, height, x, y) {
        d3.select('#lipSvg')
            .append('image')
            .attr('id', id)
            .attr('href', href)
            .attr('width', width)
            .attr('height', height)
            .attr('x', x)
            .attr('y', y)
            .attr('cursor', 'pointer')
            .call(d3.drag().on('start', (event) => dragstarted(event, id)));
    }

    function dragstarted(event, id) {
        const subject = d3.select(this);
        subject.classed('draggable', true);
        event.on('drag', dragged).on('end', ended);
        const startX = event.x;
        const startY = event.y;

        function dragged(event) {
            if (selected === 'maxillary') {
                switch (id) {
                    case 'rightArrow':
                        return setImageWidth(imageWidth + event.x - startX);
                    case 'leftArrow':
                        return setImageWidth(imageWidth + startX - event.x);
                    case 'topArrow':
                        const dy = startY - event.y;
                        setImageHeight(imageHeight + dy);
                        setDesiredCoord([
                            desiredCoord[0],
                            desiredCoord[1] - dy,
                        ]);
                        return;
                    case 'bottomArrow':
                        return setImageHeight(imageHeight + event.y - startY);
                    default:
                        return setDesiredCoord([
                            desiredCoord[0] + event.x - startX,
                            desiredCoord[1] + event.y - startY,
                        ]);
                }
            } else {
                switch (id) {
                    case 'rightArrow':
                        return setMdWidth(mdWidth + event.x - startX);
                    case 'leftArrow':
                        return setMdWidth(mdWidth + startX - event.x);
                    case 'topArrow':
                        const dy = startY - event.y;
                        setMdHeight(mdHeight + dy);
                        setMdCoord([mdCoord[0], mdCoord[1] - dy]);
                        return;
                    case 'bottomArrow':
                        return setMdHeight(mdHeight + event.y - startY);
                    default:
                        return setMdCoord([
                            mdCoord[0] + event.x - startX,
                            mdCoord[1] + event.y - startY,
                        ]);
                }
            }
        }

        function ended(event) {
            subject.classed('draggable', false);
        }
    }

    return (
        <>
            <ModalPosition>
                <Modal title='Adjustment' secondaryText='Previous' idx={1}>
                    <ContentWrapper>
                        <span> Smile Library</span>
                        <RowItem>
                            <SelectBtn
                                selected={selected === 'maxillary'}
                                onClick={() => setSelected('maxillary')}
                            >
                                Maxillary
                            </SelectBtn>
                            <SelectBtn
                                selected={selected === 'mandibular'}
                                onClick={() => setSelected('mandibular')}
                            >
                                Mandibular
                            </SelectBtn>
                        </RowItem>
                        <LibraryWrap>
                            {selected === 'maxillary'
                                ? MxLibrary.map((d, i) => (
                                      <LibraryButton
                                          key={i}
                                          onClick={() => handleSelect(i)}
                                          selected={mxLib === i}
                                      >
                                          {d.label}
                                      </LibraryButton>
                                  ))
                                : MdLibrary.map((d, i) => (
                                      <LibraryButton
                                          key={i}
                                          onClick={() => handleSelect(i)}
                                          selected={mdLib === i}
                                      >
                                          {d.label}
                                      </LibraryButton>
                                  ))}
                        </LibraryWrap>
                    </ContentWrapper>
                </Modal>
            </ModalPosition>

            <ModalPosition type='right'>
                <PatientInfo toggle={true} userForm={userForm} />
            </ModalPosition>
        </>
    );
};

export default AdjustmentContainer;
