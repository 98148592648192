import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

const useConversionImgStore = create((set, get) => ({
    isConversion: false,

    methods: {
        setIsConversion: (state) => set({ isConversion: state }),
    },
}));

export const useConversionImgStoreMethods = () =>
    useConversionImgStore((state) => state.methods, shallow);

export const useConversionImgStoreAndMethods = (stateGetter, flags) => [
    useConversionImgStore(stateGetter, flags),
    useConversionImgStore((state) => state.methods, shallow),
];

export default useConversionImgStore;
