import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { paths } from './modules/defines/paths';
import AppLayout from './layouts/AppLayout';
import Share from 'pages/Share';
import Chart from './pages/Chart';
import Viewer from './pages/Viewer';
import Home from './pages/Home';

function App() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [windowHeight, setWindowHeight] = useState(window.innerHeight);

    useEffect(() => {
        function handleResize() {
            setWindowWidth(window.innerWidth);
            setWindowHeight(window.innerHeight);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            <Routes>
                <Route
                    path={paths.home}
                    element={
                        <AppLayout
                            windowWidth={windowWidth}
                            windowHeight={windowHeight}
                        />
                    }
                >
                    <Route index element={<Home />}></Route>
                    <Route path={paths.chart} element={<Chart />}></Route>
                    <Route path={paths.viewer} element={<Viewer />}></Route>
                    <Route path={paths.share} element={<Share />}></Route>
                </Route>
            </Routes>
        </div>
    );
}

export default App;
