const apiHost = 'https://test.ddhaim.api.ddhinc.net';

export const paths = {
    home: '/',
    // auth: '/auth',
    // login: '/auth/login',
    chart: '/chart',
    viewer: '/viewer/:id',
    share: '/share/:id',
};

export const apiPath = {
    login: `${apiHost}/public/login`,
    loginCheck: `${apiHost}/api/check`,
    fileUpload: `${apiHost}/minio/upload`,
    fileDownload: `${apiHost}/minio/download`,
    share: `${apiHost}/api/dsd/share`,
    pin: `${apiHost}/public/pin/verify`,
    detection: `${apiHost}/ai/judy/eyes_lip_detection`,
};
