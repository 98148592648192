import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useRotateStore = create(
    persist(
        (set, get) => ({
            degree: 0,
            teethDegree: 0,

            setDegree: (value) => {
                set({ degree: value });
            },
            setTeethDegree: (value) => {
                set({ teethDegree: value });
            },

            initialize: () =>
                set({
                    degree: 0,
                }),
        }),
        {
            name: 'degree',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useRotateStore;
