import { Checkbox } from '@carbon/react';
import Modal, { ContentWrapper, ModalPosition } from 'components/common/Modal';
import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { SendAlt, Export } from '@carbon/icons-react';
import PatientInfo from 'components/viewer/PatientInfo';
import ExportModal from 'components/Chart/modal/ExportModal';
import ShareModal from 'components/Chart/modal/ShareModal';
import * as d3 from 'd3';
import useViewerStepStore from 'modules/stores/ViewerStep';
import ConfirmModal from 'components/Chart/modal/ConfirmModal';
import { useMutation } from '@apollo/client';
import { DELETE_CHART_DATA } from 'modules/queries/viewer';
import DefaultModal from 'components/Chart/modal/DefaultModal';

export const RowItem = styled.div`
    display: flex;
    width: 100%;
    margin-top: 24px;
    justify-content: space-between;
`;
const SideBtn = styled.button`
    background-color: ${palette.primary.gray};
    color: ${palette.white};
    width: 100%;
    height: 46px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;

    & + & {
        margin-left: 16px;
    }
`;
const SimulationContainer = ({
    setMirror,
    mirror,
    userForm,
    patientInfo,
    setIsError,
}) => {
    const [isExportModal, setIsExportModal] = useState(false);
    const [exportList, setExportList] = useState([]);
    const [isShareModal, setIsShareModal] = useState(false);
    const { previous, nextStep } = useViewerStepStore();
    const { setPrevious, setStep } = useViewerStepStore();
    const [isSuccess, setIsSuccess] = useState(false);

    const handleChecked = () => {
        // checked 상관없이 사진 좌우반전
        if (mirror === -1) setMirror(1);
        else setMirror(-1);
    };

    useEffect(() => {
        d3.select('#face').selectAll('image').remove();
        d3.select('#face').selectAll('g').remove();
    }, []);

    useEffect(() => {
        if (isExportModal) {
            const arr =
                patientInfo.attributes.ddhaim_charts.data[0].attributes
                    .ddhaim_chart_data.data;

            setExportList(arr);
        }
    }, [isExportModal, patientInfo]);

    const handleResultDelete = () => {
        const data =
            patientInfo.attributes.ddhaim_charts.data[0].attributes
                .ddhaim_chart_data.data;
        if (data.length < 2) {
            setIsError(true);
            setPrevious(false);
            return;
        }
        const id =
            patientInfo.attributes.ddhaim_charts.data[0].attributes
                .ddhaim_chart_data.data[1].id;
        deleteChart({
            notifyOnNetworkStatusChange: true,
            variables: {
                updateDdhaimChartDataId: id,
                data: {
                    deletedAt: new Date().toISOString(),
                    image_type_cd: '00730',
                },
            },
            onCompleted: (res) => {
                setPrevious(false);
                setStep(nextStep);
            },
            onError: (e) => {
                console.log(e);
            },
        });
    };

    const [deleteChart] = useMutation(DELETE_CHART_DATA);

    return (
        <>
            <ModalPosition>
                <Modal
                    title='Simulation'
                    secondaryText='Previous'
                    primaryText='Share'
                    idx={3}
                    setIsShareModal={setIsShareModal}
                >
                    <ContentWrapper>
                        <Checkbox
                            labelText='Mirror'
                            id='mirror'
                            onChange={handleChecked}
                        />
                        <RowItem>
                            {/* <SideBtn onClick={() => setIsShareModal(true)}>
                                <SendAlt width='24' height='20' /> Share
                            </SideBtn> */}
                            <SideBtn onClick={() => setIsExportModal(true)}>
                                <Export width='24' height='20' />
                                Export
                            </SideBtn>
                        </RowItem>
                    </ContentWrapper>
                </Modal>
            </ModalPosition>
            <ModalPosition type='right'>
                <PatientInfo userForm={userForm} />
            </ModalPosition>

            <ExportModal
                open={isExportModal}
                exportList={exportList}
                setExportList={setIsExportModal}
                closeExport={() => setIsExportModal(false)}
                userForm={userForm}
            />
            <ShareModal
                userForm={userForm}
                open={isShareModal}
                closeShare={() => setIsShareModal(false)}
                setIsSuccess={setIsSuccess}
            />
            {isSuccess && (
                <DefaultModal
                    open={isSuccess}
                    title='Success'
                    text='Sent an email successfully'
                    onClose={() => {
                        setIsSuccess(false);
                    }}
                />
            )}

            <ConfirmModal
                open={previous}
                onClose={() => setPrevious(false)}
                onDelete={handleResultDelete}
            />
        </>
    );
};

export default SimulationContainer;
