import React from 'react';
import LoginContainer from '../containers/LoginContainer';
import { usePathnameStoreMethods } from 'modules/stores/PathnameStore';
import { useEffect } from 'react';

const Home = () => {
    const { setPathname } = usePathnameStoreMethods();

    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    return <LoginContainer />;
};

export default Home;
