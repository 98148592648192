import React from 'react';
import { styled } from 'styled-components';

const StyledRect = styled.rect`
    transition: opacity 0.2s;
    width: 24px;
    height: 24px;
    rx: 12px;
    opacity: 0.5;
    fill: #393939;
    &:hover {
        /* fill: #ff0000; 원하는 hover 색상으로 변경 */
        opacity: 1;
    }
`;

const TrashCan = () => (
    <svg
        width='30'
        height='30'
        viewBox='0 0 24 24'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <StyledRect />
        <path d='M11 10H10V16H11V10Z' fill='white' />
        <path d='M14 10H13V16H14V10Z' fill='white' />
        <path
            d='M6 7V8H7V18C7 18.2652 7.10536 18.5196 7.29289 18.7071C7.48043 18.8946 7.73478 19 8 19H16C16.2652 19 16.5196 18.8946 16.7071 18.7071C16.8946 18.5196 17 18.2652 17 18V8H18V7H6ZM8 18V8H16V18H8Z'
            fill='white'
        />
        <path d='M14 5H10V6H14V5Z' fill='white' />
    </svg>
);

export default TrashCan;
