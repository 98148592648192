import React from 'react';
import {
    ComposedModal,
    ModalHeader,
    ModalFooter,
    Button,
    ModalBody,
} from '@carbon/react';

const LogoutModal = ({ onClose, open, onClick }) => {
    return (
        <ComposedModal open={open} size='xs' onClose={onClose}>
            <ModalHeader title='Logout' />
            <ModalBody>
                <p style={{ marginBottom: '1rem' }}>
                    Do you really want to log out?
                </p>
            </ModalBody>
            <ModalFooter>
                <Button kind='secondary' onClick={onClose}>
                    Cancel
                </Button>
                <Button kind='primary' onClick={onClick}>
                    Confirm
                </Button>
            </ModalFooter>
        </ComposedModal>
    );
};

export default LogoutModal;
