import React, { useState } from 'react';
import { ModalLayout } from 'components/common/Modal';
import { Accordion, AccordionItem, Toggle } from '@carbon/react';
import { ModalText } from '../common/Modal';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import Eye from 'components/icons/Eye';
import { useDesignViewStoreMethods } from 'modules/stores/DesignView';
import useViewerStepStore from '../../modules/stores/ViewerStep';
import useDesignViewStore from '../../modules/stores/DesignView';

const Wrpaper = styled.div`
    .cds--accordion__heading {
        background-color: ${palette.white};
        color: ${palette.primary.blue};
    }

    .cds--accordion__content {
        width: 100%;
        padding: 15px 0;
        background-color: ${palette.gray[5]};
        div {
            font-size: 14px;
            display: flex;
            justify-content: space-between;
        }

        .cds--toggle {
            height: 24px;
            padding: 0;
        }
    }
`;

const ToggleWrapper = styled.div`
    div + svg {
        margin-left: 16px;
    }
`;
const PatientInfo = ({ toggle, userForm }) => {
    const { setDesignView } = useDesignViewStoreMethods();
    const { designView } = useDesignViewStore();

    const handleDesignToggle = (value) => {
        setDesignView(value);
    };

    return (
        <ModalLayout>
            <Wrpaper>
                <Accordion className='custom-class'>
                    <AccordionItem title='Patient Info'>
                        <div style={{ padding: '8px 16px' }}>
                            {userForm && userForm.patientName}
                        </div>
                        <div style={{ padding: '8px 16px' }}>
                            {' '}
                            {userForm && userForm.gender === false
                                ? 'Male'
                                : 'Female'}{' '}
                        </div>
                        <div style={{ padding: '8px 16px' }}>
                            {userForm && userForm.birthDate}
                        </div>
                    </AccordionItem>
                    {toggle && (
                        <AccordionItem title='View Controller'>
                            {/* <div style={{ padding: '3px 16px' }}>
                                Teeth guide
                                <ToggleWrapper>
                                    <Toggle
                                        id='teeh-guide'
                                        defaultToggled={
                                            step === 1 ? true : false
                                        }
                                        hideLabel
                                        onToggle={handleGuideToggle}
                                    />
                                    <Eye type={guideView ? true : false} />
                                </ToggleWrapper>
                            </div> */}
                            <div style={{ padding: '3px 16px' }}>
                                Teeth design
                                <ToggleWrapper>
                                    <Toggle
                                        id='teeh-guide'
                                        defaultToggled={true}
                                        hideLabel
                                        onToggle={handleDesignToggle}
                                    />
                                    <Eye type={designView ? true : false} />
                                </ToggleWrapper>
                            </div>
                        </AccordionItem>
                    )}
                </Accordion>
            </Wrpaper>
        </ModalLayout>
    );
};

export default PatientInfo;
