import React from 'react';

const Left = ({ color }) => (
    <svg
        width='5'
        height='8'
        viewBox='0 0 5 8'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M5 8.00098L0 4.00098L5 0.000976562V8.00098Z'
            fill={color || '#161616'}
        />
    </svg>
);

export default Left;
