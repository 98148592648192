import qs from 'qs';
import base64 from 'base-64';

import { apiPath } from 'modules/defines/paths';
import { httpAPI } from './http';

const AuthAPI = (() => {
    function AuthAPIConstructor() {}
    AuthAPIConstructor.prototype = {
        login(data) {
            data.password = base64.encode(data.password);
            return new Promise((resolve, reject) => {
                httpAPI('', apiPath.login, { data: qs.stringify(data) })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        loginCheck(cookies) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPath.loginCheck, {
                    headers: {
                        Authorization: `bearer ${cookies[0].token}`,
                    },
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        loginSuccess(res, cookies) {
            const userForm = { ...res };
            const setCookie = cookies[1];
            const token = res.token;
            const refreshToken = res.refresh_token;
            delete res.token;
            const infos = userForm;
            setCookie('token', token, { path: '/' });
            setCookie('refreshToken', refreshToken, { path: '/' });
            setCookie('i', JSON.stringify(infos), { path: '/' });
        },
    };

    return AuthAPIConstructor;
})();

export const authAPI = new AuthAPI();
