import axios from 'axios';
import { paths } from 'modules/defines/paths';
import { Cookies } from 'react-cookie';

const downCookies = new Cookies();

const defaultOptions = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    },
};

const apiHost =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_REST_PROD
        : process.env.REACT_APP_REST_DEV;

export const axiosRefreshToken = axios.create({
    baseURL: `${apiHost}`,
});

const axiosConnection = axios.create({});
let isTokenRefreshing = false;
const refreshSubscribers = [];

const onTokenRefreshed = (accessToken) => {
    refreshSubscribers.map((callback) => callback(accessToken));
};

const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
};

// 로그인 관련 에러 코드
const loginErrorCode = [
    'J001',
    'J005',
    'C001',
    'C002',
    'M003',
    'M005',
    'K007',
    'K006',
    'K013',
    'K016',
    'K017',
    'K022',
];

axiosConnection.interceptors.response.use(
    async (response) => {
        if (response.status === 200) {
            if (response.config.method === 'put') {
                return;
            }
            if (response.data.success === true) {
                if (response.data.status === 'ok') {
                    return response.data; // file delete status
                }
                if (response.statusText === 'OK') {
                    return response.data;
                }
                return response.data.data;
            } else if (
                loginErrorCode.includes(response.data.error.error_code)
            ) {
                return Promise.reject(response.data.error);
            } else if (response.data.success === false) {
                return Promise.reject(response.data.error);
            } else {
                // TODO: check unhandled error
                console.log('HTTP ERROR:', response);
                return Promise.reject(response.data.error);
            }
        }
    },

    async (error) => {
        const { config, response } = error;
        console.log(error);
        // const status = response.status;
        if (response.status === 401) {
            const originalRequest = config;
            if (!isTokenRefreshing) {
                isTokenRefreshing = true;
                const refreshToken = downCookies.get('refreshToken');
                if (error.response.data.error.error_code === 'J002') {
                    originalRequest._retry = true;

                    try {
                        const refresh = await axiosRefreshToken.post(
                            '/api/refresh-token',
                            {
                                refreshToken,
                            }
                        );
                        if (refresh.status === 200) {
                            console.log('token:', downCookies.get('token'));

                            downCookies.remove('token', { path: '/' });
                            downCookies.remove('refreshToken', { path: '/' });
                            downCookies.set('token', refresh.data.data.token);
                            downCookies.set(
                                'refreshToken',
                                refresh.data.data.refresh_token
                            );
                            isTokenRefreshing = false;
                            axiosConnection.defaults.headers.Authorization = `Bearer ${refresh.data.data.token}`;
                            config.headers.Authorization = `Bearer ${refresh.data.data.token}`;
                            console.log(
                                'refresh token:',
                                refresh.data.data.token
                            );
                            return axiosConnection(originalRequest);
                        }
                    } catch (error) {
                        return Promise.reject(error.response.data.error);
                    }
                }
            }
        } else {
            // network error?
            console.log('retry');
        }
    }
);

export const httpAPI = function (host, path, callOptions) {
    const options = { ...defaultOptions, ...callOptions };

    return new Promise((resolve, reject) => {
        axiosConnection(host + path, options)
            .then((response) => {
                resolve(response);
            })
            .catch((error) => {
                if (loginErrorCode.includes(error.error_code)) {
                    reject(error);
                }
                if (error.error_code === 'J002') {
                    downCookies.remove('token', { path: '/' });
                    downCookies.remove('refreshToken', { path: '/' });
                    downCookies.remove('i', { path: '/' });
                    window.location.replace(paths.home);
                } else {
                    reject(error);
                }
            });
    });
};
