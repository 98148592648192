import React from 'react';

const UploadFace = () => (
    <svg
        width='112'
        height='144'
        viewBox='0 0 112 144'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <g clip-path='url(#clip0_467_119147)'>
            <path
                d='M79.9999 5.24245L80.0496 5.26712C83.7018 6.88302 87.1304 9.00466 90.0993 11.6567C100.397 20.834 106.696 34.2916 109.702 47.8849C115.342 73.3569 110.584 101.432 94.8198 122.303C91.8757 126.188 88.5465 129.79 84.9068 133.046C68.5465 147.664 43.4409 147.664 27.0807 133.046C23.4409 129.79 20.0993 126.188 17.1677 122.303C1.40368 101.432 -3.35409 73.3569 2.28566 47.8849C5.29188 34.2916 11.59 20.834 21.8881 11.6567C24.8695 9.00466 28.2857 6.89535 31.9378 5.26712L31.9875 5.24245C47.2173 -1.75155 64.7701 -1.75155 79.9999 5.24245Z'
                fill='url(#paint0_radial_467_119147)'
                fill-opacity='0.6'
            />
            <path
                d='M48.3727 92.8586C48.3727 92.8586 48.3478 92.8586 48.3354 92.8586C44.4969 92.7969 40.1491 92.7229 36.1739 90.9097C32.2609 89.1211 28.2982 84.8408 28.8447 79.6601C29.3416 74.874 33.1429 71.9136 36.4845 69.2986C37.0808 68.8298 37.677 68.3611 38.2485 67.8924C45.2423 62.0949 49.118 52.5352 48.1367 43.5552C47.1056 34.2052 40.6708 25.8667 32.4845 23.2886C23.9006 20.5749 13.5777 24.9662 10.3975 32.6756C9.88822 33.9092 8.47207 34.4889 7.24225 33.9955C6.00002 33.4898 5.41617 32.0836 5.91306 30.8624C10.0621 20.7969 22.9069 15.2215 33.9503 18.7123C44.0746 21.9071 51.7143 31.6888 52.9565 43.0495C54.1367 53.7563 49.6895 64.6976 41.3541 71.6052C40.7578 72.0986 40.1242 72.6044 39.4783 73.0978C36.6336 75.3181 33.9503 77.4274 33.6646 80.1781C33.3789 82.8795 35.7392 85.4329 38.1988 86.5677C41.2423 87.9616 44.8944 88.0233 48.41 88.0849C49.7516 88.1096 50.8199 89.2074 50.795 90.5273C50.7702 91.8471 49.6895 92.8956 48.3727 92.8956V92.8586Z'
                fill='black'
            />
            <path
                d='M107.516 38.2635C106.559 38.2635 105.652 37.6961 105.267 36.771C102.298 29.5426 94.7453 24.3125 86.8944 24.0288C78.9938 23.7574 71.1304 28.4571 67.6646 35.4511C67.0683 36.6476 65.6149 37.1287 64.4223 36.5489C63.2174 35.9568 62.7329 34.526 63.3168 33.3295C67.6025 24.6826 77.3664 18.8727 87.0683 19.2181C96.7702 19.5511 106.099 26.0271 109.764 34.9454C110.273 36.1789 109.677 37.5851 108.435 38.0785C108.137 38.2018 107.826 38.2635 107.516 38.2635Z'
                fill='black'
            />
            <path
                d='M89.2547 47.3915C89.2547 50.1545 86.9938 52.3995 84.2112 52.3995C81.4286 52.3995 79.1677 50.1545 79.1677 47.3915C79.1677 44.6284 81.4286 42.3834 84.2112 42.3834C86.9938 42.3834 89.2547 44.6284 89.2547 47.3915Z'
                fill='black'
            />
            <path
                d='M32.1118 52.3995C34.8972 52.3995 37.1553 50.1573 37.1553 47.3915C37.1553 44.6256 34.8972 42.3834 32.1118 42.3834C29.3263 42.3834 27.0683 44.6256 27.0683 47.3915C27.0683 50.1573 29.3263 52.3995 32.1118 52.3995Z'
                fill='black'
            />
            <path
                d='M53.1056 130.098C37.205 130.098 22.1491 119.799 16.4969 105.046C16.1863 104.232 16.3478 103.307 16.9193 102.628C17.4907 101.962 18.3851 101.654 19.2547 101.826C41.4658 106.316 64.9317 106.168 87.0807 101.395C87.9379 101.21 88.8447 101.506 89.4162 102.159C90 102.825 90.1739 103.738 89.8633 104.565C84.4596 119.256 69.0932 129.987 53.354 130.086H53.1304L53.1056 130.098ZM22.9441 107.426C28.882 117.985 40.9068 125.288 53.1056 125.288H53.3043C65.5901 125.214 77.5901 117.751 83.4286 107.032C73.1429 108.944 62.6335 109.918 52.1118 109.918C42.323 109.918 32.5342 109.079 22.9441 107.426Z'
                fill='black'
            />
        </g>
        <defs>
            <radialGradient
                id='paint0_radial_467_119147'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(55.9937 69.0759) rotate(90) scale(72.1604 56.1103)'
            >
                <stop stop-color='#0F62FE' />
                <stop offset='1' stop-color='#0F62FE' stop-opacity='0' />
            </radialGradient>
            <clipPath id='clip0_467_119147'>
                <rect width='112' height='144' fill='white' />
            </clipPath>
        </defs>
    </svg>
);

export default UploadFace;
