import React, { useEffect, useState } from 'react';
import {
    ComposedModal,
    ModalHeader,
    ModalFooter,
    Button,
    ModalBody,
    GlobalTheme,
    Theme,
} from '@carbon/react';
import { styled } from 'styled-components';
import HandlePatient from 'components/common/HandlePatient';
import { getDateFormat } from 'modules/utils/functions';
import { useLazyQuery, useMutation } from '@apollo/client';
import { UPDATE_DSD_PATIENT } from '../../../modules/queries/chart';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { paths } from 'modules/defines/paths';
import { viewerAPI } from 'modules/api/viewer';
import { DELETE_CHART_DATA } from 'modules/queries/viewer';

const EditPatientModal = ({
    open,
    closeModal,
    src,
    imgChange,
    onDelete,
    userForm,
    setUserForm,
    isChange,
    imgFile,
    patientInfo,
    setImgData,
    setIsChange,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [key, setKey] = useState(false);
    const [cookies] = useCookies();
    const navigate = useNavigate();

    // false로 바꿔서 해야함.
    const [warn, setWarn] = useState({
        resultPhoto: false,
        originalPhoto: false,
        patientName: false,
        birthDate: false,
        uploadDate: false,
        gender: false,
    });

    useEffect(() => {
        if (src.original) {
            setWarn({ ...warn, originalPhoto: false });
        } else if (!src.original && warn.originalPhoto) {
            setWarn({ ...warn, originalPhoto: false });
        }
    }, [src]);

    const initState = () => {
        setIsFocused(false);
        setWarn({
            originalPhoto: false,
            patientName: false,
            birthDate: false,
            uploadDate: false,
            gender: false,
        });
    };

    const [updateUserinfo] = useMutation(UPDATE_DSD_PATIENT);

    const handleChangeInput = (type, e) => {
        if (type === 'birthDate' || type === 'uploadDate') {
            setUserForm((prev) => ({ ...prev, [type]: e[0] }));
        } else {
            setUserForm((prev) => ({ ...prev, [type]: e }));
        }
        setWarn({ ...warn, [type]: false });
    };
    const [deleteChart] = useMutation(DELETE_CHART_DATA);

    const handleChangeWarn = (type) => {
        const regex = /^[A-Za-zㄱ-ㅎㅏ-ㅣ가-힣]+$/;
        let basket = warn;

        if (!src.original) {
            basket.originalPhoto = true;
        }
        if (!userForm.patientName) {
            basket.patientName = 1;
        } else if (!regex.test(userForm.patientName)) {
            basket.patientName = 2;
        }
        if (!userForm.birthDate) {
            basket.birthDate = true;
        }
        if (!userForm.uploadDate) {
            basket.uploadDate = true;
        }
        if (userForm.gender === '' || userForm.gender === undefined) {
            basket.gender = true;
        }
        setWarn(basket);
        setKey(!key);

        const promises = [];
        if (
            basket.originalPhoto === false &&
            basket.patientName === false &&
            basket.birthDate === false &&
            basket.uploadDate === false &&
            basket.gender === false
        ) {
            if (isChange.original) {
                const rqData = {
                    chart_id: userForm.chartId,
                    image_type_cd: '00729',
                    original_name: `${userForm.chartId}_00729.jpg`,
                    shoot_date: getDateFormat(new Date(userForm.uploadDate)),
                };

                const originalPromise = viewerAPI
                    .fileUpload(rqData, imgFile.original, cookies)
                    .then(
                        (response) => {
                            console.log(response, 'file upload 완');
                            setIsChange((prev) => ({
                                ...prev,
                                original: false,
                            }));
                        },
                        (error) => {
                            console.log(error);
                        }
                    );

                promises.push(originalPromise);
            }

            if (isChange.result) {
                const id =
                    patientInfo.attributes.ddhaim_charts.data[0].attributes
                        .ddhaim_chart_data.data[1].id;

                const resultPromise = new Promise((resolve, reject) => {
                    deleteChart({
                        notifyOnNetworkStatusChange: true,
                        variables: {
                            updateDdhaimChartDataId: id,
                            data: {
                                deletedAt: new Date().toISOString(),
                                image_type_cd: '00730',
                            },
                        },
                        onCompleted: (res) => {
                            console.log('result delete 완');
                            setIsChange((prev) => ({ ...prev, result: false }));
                            setImgData((prev) => ({
                                ...prev,
                                result: null,
                            }));
                            resolve(res);
                        },
                        onError: (e) => {
                            console.log(e);
                            reject(e);
                        },
                    });
                });

                promises.push(resultPromise);
            }

            let uuid;
            const updateUserPromise = new Promise((resolve, reject) => {
                updateUserinfo({
                    variables: {
                        data: {
                            first_name: userForm.patientName,
                            birth_info: getDateFormat(
                                new Date(userForm.birthDate)
                            ),
                            gender:
                                userForm.gender === 'Female'
                                    ? Boolean(true)
                                    : Boolean(false),
                            ddhaim_user: cookies.i.user_info.id,
                        },
                        updateDdhaimPatientId: userForm.strapiId,
                    },
                    onCompleted: (response) => {
                        console.log(response, 'update 완');
                        uuid =
                            response.updateDdhaimPatient.data.attributes.uuid;
                        resolve(response);
                    },
                    onError: (e) => {
                        console.log(e);
                        reject(e);
                    },
                });
            });

            promises.push(updateUserPromise);

            Promise.all(promises)
                .then(() => {
                    closeModal();
                    if (type === 'design') {
                        navigate(paths.viewer.replace(':id', uuid));
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <Theme theme='g10'>
            <ComposedModal
                open={open}
                size='md'
                onClose={() => {
                    initState();
                    closeModal();
                }}
                className='editModal'
            >
                <ModalHeader
                    title={'환자 정보 수정 (ID ' + `${userForm.id}` + ')'}
                />

                <ModalBody>
                    <HandlePatient
                        type='edit'
                        isFocused={isFocused}
                        setIsFocused={setIsFocused}
                        imgChange={imgChange}
                        textChange={handleChangeInput}
                        src={src}
                        onDelete={onDelete}
                        warn={warn}
                        userForm={userForm}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        kind='secondary'
                        onClick={() => handleChangeWarn('design')}
                    >
                        스마일 디자인
                    </Button>
                    <Button kind='primary' onClick={() => handleChangeWarn()}>
                        저장
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </Theme>
    );
};

export default EditPatientModal;
