import { gql } from '@apollo/client';

export const GET_PATIENT_INFO = gql`
    query Query(
        $filters: DdhaimPatientFiltersInput
        $ddhaimChartDataFilters2: DdhaimChartDataFiltersInput
    ) {
        ddhaimPatients(filters: $filters) {
            data {
                id
                attributes {
                    birth_info
                    gender
                    first_name
                    patient_id
                    createdAt
                    uuid
                    ddhaim_charts {
                        data {
                            id
                            attributes {
                                createdAt
                                updatedAt
                                ddhaim_chart_data(
                                    filters: $ddhaimChartDataFilters2
                                ) {
                                    data {
                                        attributes {
                                            image_type_cd
                                            updatedAt
                                            deletedAt
                                        }
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const DELETE_CHART_DATA = gql`
    mutation UpdateDdhaimChartData(
        $updateDdhaimChartDataId: ID!
        $data: DdhaimChartDataInput!
    ) {
        updateDdhaimChartData(id: $updateDdhaimChartDataId, data: $data) {
            data {
                attributes {
                    deletedAt
                }
                id
            }
        }
    }
`;
