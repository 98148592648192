import { Button } from '@carbon/react';
import SideMenu from 'icons/SideMenu';
import { palette, zIndex } from 'modules/defines/styles';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { styled } from 'styled-components';
import { getDateFormat } from '../../modules/utils/functions';
import { useNavigate } from 'react-router-dom';
import { paths } from 'modules/defines/paths';

const BoardBodyContainer = styled.div`
    display: flex;
    width: 100%;
    height: calc(100% / 12 * 10);
`;

const GridContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: ${(props) => (props.width ? props.width : '100%')};
    height: ${(props) => (props.type === 'list' ? '100%' : '')};
    ${(props) =>
        !props.patientList ||
        (props.patientList.length === 0 &&
            `
        align-items: center;
        justify-content: center;
    `)}
`;

const GridRow = styled.div`
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid ${palette.gray[2]};
    height: 10%;
    padding-left: 20px;
    &:hover {
        background-color: ${palette.gray[2]};
    }
`;

const GridCell = styled.div`
    display: flex;
    padding-left: ${(props) =>
        props.paddingleft ? props.paddingleft : '16px'};
    justify-content: ${(props) => props.justifycontent && props.justifycontent};
    width: ${(props) => props.w};
    height: 100%;
    font-size: 14px;
    color: ${palette.gray[3]};
    align-items: center;
    background-color: ${(props) =>
        props.backgroundcolor ? 'rgba(141, 141, 141, 0.12)' : null};
`;

const InnerButtonContainer = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 100%;
    cursor: pointer;
    button + button {
        margin-left: ${(props) =>
            props.type === 'menuButton' ? '6px' : '3px'};
    }
`;

const SideMenuWrapper = styled.div`
    width: 150px;
    height: 192px;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: ${zIndex.popupDialog};
    filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));
`;

const MenuItem = styled.button`
    border: none;
    height: 48px;
    width: 100%;
    padding: 0 16px;
    display: flex;
    font-size: 14px;
    align-items: center;
    background-color: ${palette.gray[5]};
    &:hover {
        background-color: ${palette.gray[2]};
    }
    &:active {
        background-color: ${palette.gray[4]};
    }
`;

const SIDE_MENU = ['Edit', 'Delete', 'Share', 'Export'];
const PatientListBody = ({
    patientList,
    onShare,
    onModal,
    onDelete,
    onExport,
    onEdit,
}) => {
    const [listItems, setListItems] = useState({});
    const navigate = useNavigate();

    useEffect(() => {
        if (patientList && patientList.length > 0) {
            setListItems(patientList.map((el) => ({ ...el, showMenu: false })));
        }
    }, [patientList]);

    const handleToggleMenu = (e, idx) => {
        e.stopPropagation();
        const newList = listItems.map((item, index) => ({
            ...item,
            showMenu: index === idx ? !item.showMenu : false,
        }));
        setListItems(newList);
    };

    const handleSideMenuClick = (e, idx, i, info) => {
        // idx : side menu index
        // i : patient list index
        onEdit(info, idx);
        handleToggleMenu(e, i);
    };

    const modalRef = useRef(null);

    const handleOutsideClick = useCallback(
        (event) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target) &&
                !event.target.classList.contains('sideMenuButton')
            ) {
                const newList = listItems.map((item) => ({
                    ...item,
                    showMenu: false,
                }));
                setListItems(newList);
            }
        },
        [listItems]
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, [handleOutsideClick]);

    const handleDbClickRow = async (el) => {
        const uuid = el.attributes.uuid;
        await navigate(paths.viewer.replace(':id', uuid));
    };

    return (
        <BoardBodyContainer>
            <GridContainer type='list' patientList={patientList}>
                {patientList && patientList.length !== 0 ? (
                    patientList.map((el, i) => (
                        <GridRow
                            onDoubleClick={() => handleDbClickRow(el)}
                            key={i}
                        >
                            <GridCell w={'11%'}>
                                {el.attributes.patient_id}
                            </GridCell>
                            <GridCell w={'12%'}>
                                {el.attributes.gender === true ? 'F' : 'M'}
                            </GridCell>
                            <GridCell w={'17%'}>
                                {el.attributes.birth_info}
                            </GridCell>
                            <GridCell w={'17%'}>
                                {el.attributes.first_name}
                            </GridCell>
                            <GridCell w={'19%'}>
                                {getDateFormat(el.attributes.updatedAt)}
                            </GridCell>
                            <GridCell w={'20%'}>
                                {getDateFormat(el.attributes.createdAt)}
                            </GridCell>
                            <GridCell
                                w={'52px'}
                                justifycontent='flex-end'
                                paddingleft='0'
                                style={{ position: 'absolute', right: 0 }}
                                backgroundcolor={
                                    listItems &&
                                    listItems[i] &&
                                    listItems[i].showMenu
                                }
                                onClick={(e) => handleToggleMenu(e, i)}
                            >
                                <InnerButtonContainer className='sideMenuButton'>
                                    <SideMenu />
                                </InnerButtonContainer>

                                {listItems &&
                                    listItems[i] &&
                                    listItems[i].showMenu && (
                                        <SideMenuWrapper ref={modalRef}>
                                            {SIDE_MENU.map((d, idx) => (
                                                <MenuItem
                                                    key={idx}
                                                    onClick={(e) =>
                                                        handleSideMenuClick(
                                                            e,
                                                            idx,
                                                            i,
                                                            el
                                                        )
                                                    }
                                                >
                                                    {d}
                                                </MenuItem>
                                            ))}
                                        </SideMenuWrapper>
                                    )}
                            </GridCell>
                        </GridRow>
                    ))
                ) : (
                    <>
                        <div>No Result, please add new patient</div>
                        {/* <Button
                            className='addPatientButton'
                            onClick={() => console.log(123123)}
                        >
                            Add new patient
                        </Button> */}
                    </>
                )}
            </GridContainer>
        </BoardBodyContainer>
    );
};

export default PatientListBody;
