import qs from 'qs';
import { apiPath } from 'modules/defines/paths';
import { httpAPI } from './http';

const ViewerAPI = (() => {
    function ViewerAPIConstructor() {}

    ViewerAPIConstructor.prototype = {
        fileUpload(data, file, cookies) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPath.fileUpload, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `bearer ${cookies.i.token}`,
                        csrf: '',
                    },
                    data: qs.stringify(data),
                })
                    .then((response) => {
                        httpAPI('', response.presignedUrl, {
                            method: 'PUT',
                            headers: {
                                'Content-Type': 'image',
                            },
                            data: file,
                        })
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((error) => {
                                reject(error);
                            });
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        fileDownload(data, cookies) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPath.fileDownload, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `bearer ${cookies.i.token}`,
                    },
                    data: qs.stringify(data),
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        detection(data, cookies) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPath.detection, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `bearer ${cookies.i.token}`,
                    },
                    data: qs.stringify(data),
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
    };
    return ViewerAPIConstructor;
})();

export const viewerAPI = new ViewerAPI();
