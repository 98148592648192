import Modal, { ModalPosition } from 'components/common/Modal';
import React, { useEffect, useState } from 'react';
import PatientInfo from 'components/viewer/PatientInfo';
import {
    ReactCompareSlider,
    ReactCompareSliderHandle,
    ReactCompareSliderImage,
} from 'react-compare-slider';
import { palette, size } from 'modules/defines/styles';

const ShareSimulation = ({ userForm }) => {
    const [img, setImg] = useState();
    const [imgSize, setImgSize] = useState();
    const [windowRatio, setWindowRatio] = useState();

    useEffect(() => {
        const newImg = new Image();
        newImg.src =
            userForm && userForm.path && userForm.path[0].presignedPath;
        newImg.onload = () => {
            setImg(newImg);
        };
    }, [userForm]);

    useEffect(() => {
        if (img && img.complete) {
            setImgSize({ w: img.width, h: img.height });
            setWindowRatio(window.innerHeight / img.height);
        }
    }, [img]);

    return (
        <>
            <ModalPosition style={{ zIndex: '5000' }}>
                <PatientInfo userForm={userForm} />
            </ModalPosition>

            <ReactCompareSlider
                handle={
                    <ReactCompareSliderHandle
                        buttonStyle={{
                            background: 'transparent',
                            backdropFilter: 'none',
                            color: palette.blue[6],
                        }}
                        linesStyle={{
                            color: palette.blue[6],
                        }}
                    />
                }
                itemOne={
                    <ReactCompareSliderImage
                        src={
                            userForm &&
                            userForm.path &&
                            userForm.path[0].presignedPath
                        }
                        alt='Image one'
                    />
                }
                itemTwo={
                    <ReactCompareSliderImage
                        src={
                            userForm &&
                            userForm.path &&
                            userForm.path[1].presignedPath
                        }
                        alt='Image two'
                    />
                }
                style={{
                    width: `${windowRatio && windowRatio * imgSize.w}px`,
                    height: `${
                        windowRatio && windowRatio * imgSize.h - size.header
                    }px`,
                    position: 'relative',
                }}
            />
        </>
    );
};

export default ShareSimulation;
