import React from 'react';
import {
    ComposedModal,
    ModalHeader,
    ModalFooter,
    Button,
    ModalBody,
} from '@carbon/react';

const ConfirmModal = ({ open, onClose, onDelete }) => {
    return (
        <ComposedModal open={open} size='xs' onClose={onClose}>
            <ModalHeader title='Delete' />
            <ModalBody>
                <p style={{ marginBottom: '1rem' }}>
                    If you return to the previous step, the existing results are
                    deleted.
                </p>
            </ModalBody>
            <ModalFooter>
                <Button kind='secondary' onClick={onClose}>
                    Cancel
                </Button>
                <Button kind='primary' onClick={onDelete}>
                    Confirm
                </Button>
            </ModalFooter>
        </ComposedModal>
    );
};

export default ConfirmModal;
