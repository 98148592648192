import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from 'styled-components';
import HeaderContainer from 'containers/common/HeaderContainer';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import LogoutModal from '../components/Chart/modal/LogoutModal';

const AppWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #e5e5e5;
    /* margin: auto; */
    /* width: ${window.innerWidth}px; */
    width: ${(props) => props.windowwidth}px;
    height: ${(props) => props.windowheight}px;
`;

const AppLayout = ({ windowWidth, windowHeight }) => {
    const [isConfirm, setIsConfirm] = useState(false);
    const navigate = useNavigate();
    const cookies = useCookies();
    const removeCookies = cookies[2];

    const handleLogout = () => {
        setIsConfirm(false);

        removeCookies('token');
        removeCookies('i');
        removeCookies('refreshToken');
        localStorage.clear();

        navigate('/', { replace: true });
    };

    return (
        <>
            <AppWrapper
                className='appWrapper'
                windowwidth={windowWidth}
                windowheight={windowHeight}
            >
                <HeaderContainer setIsConfirm={setIsConfirm} />
                <Outlet />
                {/* <PopupDialogs /> */}
                {isConfirm && (
                    <LogoutModal
                        onClose={() => setIsConfirm(false)}
                        open={isConfirm}
                        onClick={handleLogout}
                    />
                )}
            </AppWrapper>
        </>
    );
};

export default AppLayout;
