import React, { useEffect, useState, useRef } from 'react';
import AdjustmentContainer from './AdjustmentContainer';
import DesignContainer from './DesignContainer';
import PrepareContainer from './PrepareContainer';
import SimulationContainer from './SimulationContainer';
import * as d3 from 'd3';
import useViewerStepStore from '../../modules/stores/ViewerStep';
import styled from 'styled-components';
import { size, palette } from 'modules/defines/styles';
import Draggable from 'react-draggable';
import { SvgGroup, SvgWrapper } from 'components/viewer/SvgViewer';
import { viewerAPI } from 'modules/api/viewer';
import { useCookies } from 'react-cookie';
import SmileJPG from 'assets/DSD_test_before.jpg';
import {
    ReactCompareSlider,
    ReactCompareSliderImage,
    ReactCompareSliderHandle,
} from 'react-compare-slider';
import { toJpeg } from 'html-to-image';
import { Loading } from 'components/common/Loading';
import { useQuery, useLazyQuery, useMutation } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import useConversionImgStore, {
    useConversionImgStoreMethods,
} from 'modules/stores/ConversionImg';
import { GET_PATIENT_INFO } from '../../modules/queries/viewer';
import { convertURLtoFile, getDateFormat } from 'modules/utils/functions';
import useLibraryInfoStore from 'modules/stores/LibraryInfo';
import useAIresultStore from 'modules/stores/AIResult';
import useRotateStore from '../../modules/stores/RotateImg';
import DefaultModal from '../../components/Chart/modal/DefaultModal';

const ViewerWrapper = styled.div`
    height: calc(100% - ${size.header}px);
    width: 100%;
    overflow: hidden;
    position: relative;
`;

const Wrapper = styled.div`
    position: absolute;
`;

const ImgWrapper = styled.img`
    background-size: 100% auto;
    background-repeat: no-repeat;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    scale: ${(props) => props.scale};
    position: absolute;
    transform-origin: center;
    transform: ${(props) => `rotate(${props.degree}deg)`};
`;

const INITIAL_POSITION = { x: 0, y: 0 };
const MIN_SCALE = 0.1;
const MAX_SCALE = 10;

const ViewerContainer = () => {
    const zoomCanvasRef = useRef(null);
    const scaleRef = useRef(1);
    const panningRef = useRef(false);
    const viewPosRef = useRef(INITIAL_POSITION);
    const startPosRef = useRef(INITIAL_POSITION);
    const canvasRef = useRef(null);
    const location = useLocation();
    const { step, setStep } = useViewerStepStore();
    const { setIsConversion } = useConversionImgStoreMethods();
    const { isConversion } = useConversionImgStore();
    const {
        lipPoints,
        eyePoints,
        setLipPoints,
        setEyePoints,
        xCoords,
        setXCoords,
    } = useAIresultStore();
    const { degree, setDegree, teethDegree, setTeethDegree } = useRotateStore();
    const [windowRatio, setWindowRatio] = useState();
    const [imgSize, setImgSize] = useState({ w: 0, h: 0 });
    const [zoom, setZoom] = useState();
    const [rotationAngle, setRotationAngle] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [userForm, setUserForm] = useState({});
    const [mirror, setMirror] = useState(1);
    const [patientInfo, setPatientInfo] = useState();
    const [isSimulation, setIsSimulation] = useState(false);
    const [cookies] = useCookies();
    const [img, setImg] = useState(null);
    const [blobImg, setBlobImg] = useState(null);
    const [refetch, setRefetch] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isFail, setIsFail] = useState(false);
    const [presignedUrl, setPresignedUrl] = useState();
    const [resultUrl, setResultUrl] = useState();

    const getPatientInfo = useQuery(GET_PATIENT_INFO, {
        fetchPolicy: 'no-cache',
        variables: {
            filters: {
                uuid: {
                    eq: location.pathname.split('/')[2],
                },
            },
            ddhaimChartDataFilters2: {
                deletedAt: {
                    null: true,
                },
            },
        },
        onError(e) {
            console.log(e);
        },
    });

    const [getLazyPatientInfo] = useLazyQuery(GET_PATIENT_INFO);

    useEffect(() => {
        if (getPatientInfo.data) {
            const data = getPatientInfo.data.ddhaimPatients.data[0];
            if (data !== undefined) {
                setUserForm({
                    strapiId: data.id,
                    id: data.attributes.patient_id,
                    patientName: data.attributes.first_name,
                    birthDate: data.attributes.birth_info,
                    uploadDate: data.attributes.createdAt,
                    gender: data.attributes.gender,
                    chartId: data.attributes.ddhaim_charts.data[0].id,
                    uuid: data.attributes.uuid,
                });
                setPatientInfo(data);

                if (
                    data.attributes.ddhaim_charts.data[0].attributes
                        .ddhaim_chart_data.data.length > 1
                ) {
                    setStep(3);
                }
            }
        }
    }, [getPatientInfo.data, refetch]);

    useEffect(() => {
        if (refetch) {
            getLazyPatientInfo({
                fetchPolicy: 'no-cache',
                variables: {
                    filters: {
                        uuid: {
                            eq: location.pathname.split('/')[2],
                        },
                    },
                    ddhaimChartDataFilters2: {
                        deletedAt: {
                            null: true,
                        },
                    },
                },
                onCompleted: (res) => {
                    const data = res.ddhaimPatients.data[0];
                    setPatientInfo(data);
                },
            });
        }
    }, [refetch]);

    useEffect(() => {
        if (userForm.chartId) {
            const rqData = {
                chart_id: userForm.chartId,
                image_type_cd: ['00729', '00730'],
                thumbnail_only_check: false,
            };
            setIsLoading(true);

            const fetchImage = async (res) => {
                try {
                    const response = await fetch(res[0].presignedPath);
                    const blob = await response.blob();
                    setBlobImg(URL.createObjectURL(blob));

                    if (res.length > 1) {
                        const result = await fetch(res[1].presignedPath);
                        const blob2 = await result.blob();
                        setResultUrl(URL.createObjectURL(blob2));
                    }
                } catch (e) {
                    console.log(e);
                }
            };

            viewerAPI.fileDownload(rqData, cookies).then(
                (res) => {
                    fetchImage(res);
                    setPresignedUrl(res[0].presignedPath);
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [userForm]);

    useEffect(() => {
        if (blobImg) {
            const newImg = new Image();
            newImg.src = blobImg;
            newImg.onload = () => {
                setImg(newImg);
                if (step !== 0 && step !== 1) {
                    setIsLoading(false);
                }
            };
        }
    }, [blobImg]);

    useEffect(() => {
        if (img && img.complete) {
            setImgSize({ w: img.width, h: img.height });
            setWindowRatio(window.innerHeight / img.height);
        }
    }, [img]);

    useEffect(() => {
        if (isConversion) {
            // zoom
            scaleRef.current = 1;
            viewPosRef.current = { INITIAL_POSITION };
            setZoom(1);
            setTeethDegree(degree);
            setDegree(0);
            setIsSimulation(true);
        } else {
            setTeethDegree(0);
        }
    }, [canvasRef, isConversion]);

    useEffect(() => {
        if (isSimulation) {
            if (canvasRef.current === null || canvasRef.current === undefined) {
                return;
            }
            setIsLoading(true);

            toJpeg(canvasRef.current)
                .then((dataUrl) => {
                    setResultUrl(dataUrl);
                    setStep(3);
                    setIsConversion(false);
                    setIsSimulation(false);

                    const rqData = {
                        chart_id: userForm.chartId,
                        image_type_cd: '00730',
                        original_name: `${userForm.chartId}_00730.jpg`,
                        shoot_date: getDateFormat(new Date()),
                    };

                    convertURLtoFile(dataUrl).then((resolve) => {
                        viewerAPI.fileUpload(rqData, resolve, cookies).then(
                            (response) => {
                                console.log(response);
                                setIsLoading(false);
                                setRefetch(true);
                            },
                            (error) => {
                                console.log(error);
                                setIsError(true);
                            }
                        );
                    });
                })
                .catch((err) => {
                    console.log(err);
                    setIsLoading(false);
                    setIsError(true);
                    setIsConversion(false);
                    setIsSimulation(false);
                });
        }
    }, [isSimulation]);

    useEffect(() => {
        if (img && presignedUrl && windowRatio) {
            const lipValue = JSON.parse(localStorage.getItem('lipArea'));
            const eyeValue = JSON.parse(localStorage.getItem('eyeTracking'));
            if (lipValue && eyeValue && step !== 2) {
                setLipPoints(lipValue);
                setEyePoints(eyeValue);
                setIsLoading(false);

                calculateXcoord(lipValue);
            } else if (step === 0) {
                setIsLoading(true);
                const eyeArr = [];
                const lipArr = [];
                viewerAPI
                    .detection({ url: presignedUrl }, cookies)
                    .then((res) => {
                        const lipArea = res['lip-area'].map((sub) => {
                            return sub.map((el) => el * windowRatio);
                        });
                        const eyeTracking = res['eye-tracking'].map((sub) => {
                            return sub.map((el) => el * windowRatio);
                        });
                        lipArr.push(lipArea);
                        eyeArr.push(eyeTracking);
                        setIsLoading(false);
                        setLipPoints(lipArr[0]);
                        setEyePoints(eyeArr[0]);

                        localStorage.setItem(
                            'lipArea',
                            JSON.stringify(lipArr[0])
                        );
                        localStorage.setItem(
                            'eyeTracking',
                            JSON.stringify(eyeArr[0])
                        );

                        calculateXcoord(lipArr[0]);
                    })
                    .catch((e) => {
                        console.log(e);
                        setIsFail(true);
                        setIsLoading(false);
                        calculateXcoord(lipPoints);
                        localStorage.setItem(
                            'lipArea',
                            JSON.stringify(lipPoints)
                        );
                        localStorage.setItem(
                            'eyeTracking',
                            JSON.stringify(eyePoints)
                        );
                    });
            }
        }
    }, [img, windowRatio, step, degree]);

    useEffect(() => {
        setMirror(1);
    }, [step]);

    const handleMouseDown = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;
        e.preventDefault();
        startPosRef.current = {
            x: offsetX - viewPosRef.current.x,
            y: offsetY - viewPosRef.current.y,
        };
        panningRef.current = true;
    };

    const handleMouseUp = (e) => {
        panningRef.current = false;
    };

    const handleMouseMove = (e) => {
        const { offsetX, offsetY } = e.nativeEvent;
        e.preventDefault();
        if (!panningRef.current) {
            return;
        }
        viewPosRef.current = {
            x: offsetX - startPosRef.current.x,
            y: offsetY - startPosRef.current.y,
        };
    };
    const handleWheel = (e, step) => {
        if (step === 3) return;
        const { offsetX, offsetY } = e.nativeEvent;
        const xs = (offsetX - viewPosRef.current.x) / scaleRef.current;
        const ys = (offsetY - viewPosRef.current.y) / scaleRef.current;
        const delta = -e.deltaY;
        const newScale =
            delta > 0 ? scaleRef.current * 1.2 : scaleRef.current / 1.2;

        if (newScale >= MIN_SCALE && newScale <= MAX_SCALE) {
            scaleRef.current = newScale;
            viewPosRef.current = {
                x: offsetX - xs * scaleRef.current,
                y: offsetY - ys * scaleRef.current,
            };
            setZoom(newScale);
        }
    };

    const handleClose = () => {
        setIsError(false);
        setIsFail(false);
    };

    function calculateXcoord(arr) {
        let smallestXCoord = arr[0];
        let largestXCoord = arr[0];

        for (let i = 1; i < arr.length; i++) {
            const currentCoord = arr[i];
            const currentX = currentCoord[0];
            if (currentX < smallestXCoord[0]) {
                smallestXCoord = currentCoord;
            }
            if (currentX > largestXCoord[0]) {
                largestXCoord = currentCoord;
            }
        }

        setXCoords({ smallestXCoord, largestXCoord });
    }
    return (
        <ViewerWrapper>
            {isError && (
                <DefaultModal
                    text='Try again after reloading'
                    title='Error'
                    onClose={handleClose}
                    open={isError}
                />
            )}
            {isFail && (
                <DefaultModal
                    title='Fail'
                    text='Recognition failed. The default points are displayed'
                    onClose={handleClose}
                    open={isFail}
                />
            )}
            {isLoading && <Loading />}
            <div
                ref={zoomCanvasRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onWheel={(e) => handleWheel(e, step)}
                style={{
                    width: '100%',
                    transform: `translateX(calc((100% - ${
                        windowRatio * imgSize.w
                    }px)/ 2))`,
                }}
            >
                {step !== 3 && (
                    <Draggable>
                        <SvgGroup id='imgWrapper' rotationAngle={rotationAngle}>
                            <div
                                ref={canvasRef}
                                style={{
                                    width: `${windowRatio * imgSize.w}px`,
                                    height: `${windowRatio * imgSize.h}px`,
                                    scale: `${zoom}`,
                                }}
                            >
                                <ImgWrapper
                                    referrerpolicy='no-referrer'
                                    src={img && img.src}
                                    alt=''
                                    height={windowRatio * imgSize.h}
                                    width={windowRatio * imgSize.w}
                                    degree={degree}
                                />
                                <SvgWrapper
                                    id='face'
                                    height={windowRatio * imgSize.h}
                                    width={windowRatio * imgSize.w}
                                    style={{
                                        position: 'absolute',
                                        transform: `rotate(${degree}deg)`,
                                    }}
                                />
                                {step !== 0 && (
                                    <SvgWrapper
                                        id='lipSvg'
                                        height={windowRatio * imgSize.h}
                                        width={windowRatio * imgSize.w}
                                        style={{
                                            position: 'absolute',
                                            transform: `rotate(-${teethDegree}deg)`,
                                        }}
                                    />
                                )}
                            </div>
                        </SvgGroup>
                    </Draggable>
                )}

                {step === 3 && (
                    <ReactCompareSlider
                        handle={
                            <ReactCompareSliderHandle
                                buttonStyle={{
                                    background: 'transparent',
                                    backdropFilter: 'none',
                                    color: palette.blue[6],
                                }}
                                linesStyle={{
                                    color: palette.blue[6],
                                }}
                            />
                        }
                        itemOne={
                            <ReactCompareSliderImage
                                src={img && img.src}
                                alt='Image one'
                                style={{
                                    transform: `scaleX(${mirror})`,
                                }}
                            />
                        }
                        itemTwo={
                            <ReactCompareSliderImage
                                src={resultUrl}
                                alt='Image two'
                                style={{
                                    transform: `scaleX(${mirror})`,
                                }}
                            />
                        }
                        // onPositionChange={handlePositionChange}
                        style={{
                            width: `${windowRatio * imgSize.w}px`,
                            height: `${windowRatio * imgSize.h}px`,
                            scale: zoom,
                            position: 'absolute',
                            backgroundSize: 'cover',
                            backgroundPosition: 'right',
                        }}
                    />
                )}
            </div>

            <Wrapper>
                {step === 0 ? (
                    <PrepareContainer
                        rotationAngle={rotationAngle}
                        setRotationAngle={setRotationAngle}
                        setDegree={setDegree}
                        degree={degree}
                        xCoords={xCoords}
                    />
                ) : step === 1 ? (
                    <AdjustmentContainer
                        userForm={userForm}
                        xCoords={xCoords}
                        setIsLoading={setIsLoading}
                        degree={degree}
                    />
                ) : step === 2 ? (
                    <DesignContainer
                        userForm={userForm}
                        lipPoints={lipPoints}
                        degree={degree}
                        teethDegree={teethDegree}
                    />
                ) : (
                    <SimulationContainer
                        setMirror={setMirror}
                        mirror={mirror}
                        userForm={userForm}
                        patientInfo={patientInfo}
                        setIsError={setIsError}
                    />
                )}
            </Wrapper>
        </ViewerWrapper>
    );
};

export default ViewerContainer;
