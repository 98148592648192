import React from 'react';

const SearchIcon = () => (
    <svg
        width='15'
        height='15'
        viewBox='0 0 15 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M14.5001 13.7931L10.7241 10.017C11.6314 8.9277 12.0839 7.5305 11.9873 6.11608C11.8908 4.70165 11.2526 3.37891 10.2057 2.423C9.15867 1.4671 7.78347 0.951637 6.36612 0.983845C4.94877 1.01605 3.5984 1.59345 2.59593 2.59593C1.59345 3.5984 1.01605 4.94877 0.983845 6.36612C0.951637 7.78347 1.4671 9.15867 2.423 10.2057C3.37891 11.2526 4.70165 11.8908 6.11608 11.9873C7.5305 12.0839 8.9277 11.6314 10.017 10.7241L13.7931 14.5001L14.5001 13.7931ZM2.00012 6.50012C2.00012 5.6101 2.26404 4.74007 2.75851 4.00005C3.25297 3.26003 3.95578 2.68325 4.77804 2.34266C5.60031 2.00207 6.50511 1.91295 7.37802 2.08658C8.25094 2.26022 9.05276 2.6888 9.6821 3.31814C10.3114 3.94747 10.74 4.7493 10.9137 5.62221C11.0873 6.49513 10.9982 7.39993 10.6576 8.22219C10.317 9.04446 9.7402 9.74726 9.00018 10.2417C8.26016 10.7362 7.39013 11.0001 6.50012 11.0001C5.30705 10.9988 4.16323 10.5243 3.3196 9.68064C2.47597 8.83701 2.00144 7.69319 2.00012 6.50012Z'
            fill='#525252'
        />
    </svg>
);

export default SearchIcon;
