import { Slider } from '@carbon/react';
import Modal, { ContentWrapper, ModalPosition } from 'components/common/Modal';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import * as d3 from 'd3';
import { palette } from 'modules/defines/styles';
import PatientInfo from 'components/viewer/PatientInfo';
import MxLibrary1 from 'assets/library_maxillary1.svg';
import MxLibrary2 from 'assets/library_maxillary2.svg';
import MdLibrary1 from 'assets/library_mandibular1.svg';
import useLibraryInfoStore from 'modules/stores/LibraryInfo';
import useDesignViewStore from '../../modules/stores/DesignView';

const Line = styled.div`
    border-top: 1px solid ${palette.gray[9]};
    padding-top: 24px;
    margin-top: 24px;
    width: 270px;
`;

const DesignContainer = ({ userForm, lipPoints, degree }) => {
    const [blur, setBlur] = useState(100);
    const [bright, setBright] = useState(100);
    const [shade, setShade] = useState(50);
    const [highlights, setHighlitghts] = useState(100);
    const [lipShadow, setLipShadow] = useState(50);
    const {
        desiredCoord,
        imageWidth,
        imageHeight,
        mdLib,
        mxLib,
        mdWidth,
        mdHeight,
        mdCoord,
    } = useLibraryInfoStore();
    const { designView } = useDesignViewStore();

    useEffect(() => {
        d3.select('#image').attr(
            'filter',
            `blur(${
                (blur - 50) * 0.01 < 0 ? 0 : (blur - 50) * 0.01
            }px) brightness(${bright}%) contrast(${highlights}%) grayscale(${shade}%)`
        );

        d3.select('#mdimage').attr(
            'filter',
            `blur(${
                (blur - 50) * 0.01 < 0 ? 0 : (blur - 50) * 0.01
            }px) brightness(${bright}%) contrast(${highlights}%) grayscale(${shade}%)`
        );
    }, [blur, bright, highlights, shade]);

    useEffect(() => {
        d3.select('#lipSvg').select('#leftArrow').remove();
        d3.select('#lipSvg').select('#bottomArrow').remove();
        d3.select('#lipSvg').select('#topArrow').remove();
        d3.select('#lipSvg').select('#rightArrow').remove();
        d3.select('#lipSvg').select('#moveArrow').remove();
        d3.select('#lipSvg').selectAll('image').remove();
        d3.select('#face').selectAll('clipPath').remove();

        if (mdLib !== '' || mxLib !== '') {
            const mxEmpty = d3.select('#image').empty();
            const mdEmpty = d3.select('#mdimage').empty();
            const points = lipPoints.map((point) => point.join(',')).join(' ');

            if (designView) {
                if (mxLib !== '' && mxEmpty) {
                    appendImage(
                        'image',
                        selectLibrary(),
                        imageWidth,
                        imageHeight,
                        desiredCoord[0] - imageWidth / 2,
                        desiredCoord[1]
                    );
                    d3.select('#face')
                        .append('clipPath')
                        .attr('id', 'clip-path')
                        .append('polygon')
                        .attr('points', points)
                        .attr('transform', `rotate(${degree})`)
                        .style('transform-origin', 'center');
                    d3.select('#image').attr('clip-path', `url(#clip-path)`);
                }

                if (mdLib !== '' && mdEmpty) {
                    appendImage(
                        'mdimage',
                        selectMdLibrary(),
                        mdWidth,
                        mdHeight,
                        mdCoord[0] - mdWidth / 2,
                        mdCoord[1]
                    );
                    d3.select('#face')
                        .append('clipPath')
                        .attr('id', 'clip-path')
                        .append('polygon')
                        .attr('points', points)
                        .attr('transform', `rotate(${degree})`)
                        .style('transform-origin', 'center');
                    d3.select('#mdimage').attr('clip-path', `url(#clip-path)`);
                }
            }

            d3.select('#image').attr(
                'filter',
                `blur(${
                    (blur - 50) * 0.01 < 0 ? 0 : (blur - 50) * 0.01
                }px) brightness(${bright}%) contrast(${highlights}%) grayscale(${shade}%)`
            );

            d3.select('#mdimage').attr(
                'filter',
                `blur(${
                    (blur - 50) * 0.01 < 0 ? 0 : (blur - 50) * 0.01
                }px) brightness(${bright}%) contrast(${highlights}%) grayscale(${shade}%)`
            );
        }
    }, [designView]);

    useEffect(() => {
        d3.select('#face').selectAll('defs').remove();
        d3.select('#face').select('#shadow-poly').remove();

        const points = lipPoints.map((point) => point.join(',')).join(' ');

        const defs = d3.select('#face').append('defs');
        const filter = defs.append('filter').attr('id', 'inner-shadow');

        filter
            .append('feGaussianBlur')
            .attr('in', 'SourceGraphic')
            .attr('stdDeviation', 100 - lipShadow)
            .attr('result', 'blur');

        filter
            .append('feComposite')
            .attr('in2', 'SourceAlpha')
            .attr('operator', 'in')
            .attr('result', 'inverse');

        d3.select('#face')
            .append('polygon')
            .attr('id', 'shadow-poly')
            .attr('points', points)
            .attr('stroke', '#000')
            .attr('fill', '#000')
            .style('filter', 'url(#inner-shadow)');
    }, [lipShadow]);

    function appendImage(id, href, width, height, x, y) {
        d3.select('#lipSvg')
            .append('image')
            .attr('id', id)
            .attr('href', href)
            .attr('width', width)
            .attr('height', height)
            .attr('x', x)
            .attr('y', y)
            .attr('cursor', 'pointer');
    }

    const selectLibrary = () => {
        switch (mxLib) {
            default:
                return MxLibrary1;
            case 1:
                return MxLibrary2;
        }
    };

    const selectMdLibrary = () => {
        switch (mdLib) {
            default:
                return MdLibrary1;
        }
    };

    const handleChange = (e, type) => {
        switch (type) {
            case 'shade':
                setShade(100 - e.value);
                return;
            case 'highlights':
                setHighlitghts(e.value * 2);
                return;
            case 'bright':
                setBright(e.value * 2);
                return;
            case 'lipShadow':
                setLipShadow(e.value);
                return;
            default:
                setBlur(200 - e.value * 2);
                return;
        }
    };

    return (
        <>
            <ModalPosition>
                <Modal idx={2} title='Design' secondaryText='Previous'>
                    <ContentWrapper>
                        <Slider
                            min={0}
                            max={100}
                            noValidate
                            value={50}
                            labelText='Blur/Sharpen'
                            onChange={(e) => handleChange(e, 'blur')}
                        />
                        <Slider
                            min={0}
                            max={100}
                            noValidate
                            value={50}
                            labelText='Highlights'
                            onChange={(e) => handleChange(e, 'bright')}
                        />
                        <Slider
                            min={0}
                            max={100}
                            noValidate
                            value={50}
                            labelText='Shade'
                            onChange={(e) => handleChange(e, 'shade')}
                        />
                        <Slider
                            min={0}
                            max={100}
                            noValidate
                            value={50}
                            labelText='Brightness'
                            onChange={(e) => handleChange(e, 'highlights')}
                        />
                        <Line>
                            <Slider
                                min={0}
                                max={100}
                                noValidate
                                value={50}
                                labelText='Shadow from lip'
                                onChange={(e) => handleChange(e, 'lipShadow')}
                            />
                        </Line>
                    </ContentWrapper>
                </Modal>
            </ModalPosition>

            <ModalPosition type='right'>
                <PatientInfo toggle={true} userForm={userForm} />
            </ModalPosition>
        </>
    );
};

export default DesignContainer;
