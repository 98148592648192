import React, { useEffect, useState } from 'react';
import ChartComponent from 'components/Chart/ChartComponent';
import DeletePatientModal from 'components/Chart/modal/DeletePatientModal';
import ExportModal from 'components/Chart/modal/ExportModal';
import ShareModal from 'components/Chart/modal/ShareModal';
import AddNewPatientModal from 'components/Chart/modal/AddNewPatientModal';
import EditPatientModal from 'components/Chart/modal/EditPatientModal';
import DeleteModal from 'components/Chart/modal/DeleteModal';
import { viewerAPI } from 'modules/api/viewer';
import { useCookies } from 'react-cookie';
import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import { GET_ALL_PATIENT_LIST_OF_DOCTOR } from 'modules/queries/chart';
import useViewerStepStore from '../modules/stores/ViewerStep';
import { DELETE_CHART_DATA } from 'modules/queries/viewer';
import useLibraryInfoStore from 'modules/stores/LibraryInfo';
import useAIresultStore from 'modules/stores/AIResult';
import { useDesignViewStoreMethods } from 'modules/stores/DesignView';
import useRotateStore from 'modules/stores/RotateImg';
import DefaultModal from 'components/Chart/modal/DefaultModal';

const itemsPerPage = 10;

const ChartContainer = () => {
    const [isDeletePatientModal, setIsDeletePatientModal] = useState(false);
    const [isExportModal, setIsExportModal] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [isShareModal, setIsShareModal] = useState(false);
    const [isAddPatientModal, setIsAddPatientModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [imgData, setImgData] = useState({
        original: null,
        result: null,
    });
    const [imgFile, setImgFile] = useState({
        original: null,
        result: null,
    });
    const [cookies] = useCookies();
    const [isDeleteModal, setIsDeleteModal] = useState(false);
    const [deletePhotoType, setDeletePhotoType] = useState('');
    const [patientInfo, setPatientInfo] = useState();
    const [isChange, setIsChange] = useState({
        original: false,
        result: false,
    });
    const [isSuccess, setIsSuccess] = useState(false);

    const [userForm, setUserForm] = useState({
        strapiId: '',
        id: '',
        patientName: '',
        birthDate: '',
        uploadDate: '',
        gender: '',
        uuid: '',
    });
    const [currentPage, setCurrentPage] = useState(1);
    const [sortingOrder, setSortingOrder] = useState('asc');
    const [sortingItem, setSortingItem] = useState('createdAt');
    const [total, setTotal] = useState();
    const [patientList, setPatientList] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [exportList, setExportList] = useState([]);
    const { setStep } = useViewerStepStore();
    const { initialized } = useAIresultStore();
    const { initiallizedLibrary } = useLibraryInfoStore();
    const { initialize } = useRotateStore();
    const { setDesignView } = useDesignViewStoreMethods();

    const totalPages = Math.ceil(total / itemsPerPage);

    useEffect(() => {
        setStep(0);
        localStorage.removeItem('lipArea');
        localStorage.removeItem('eyeTracking');
        initiallizedLibrary();
        initialized();
        setDesignView(true);
        initialize();
    }, []);

    // ! originial 파일만 업로드 가능함
    const handleImageInput = (file, type) => {
        const value = URL.createObjectURL(file);
        setImgData((prev) => ({ ...prev, [type]: value }));
        setImgFile((prev) => ({ ...prev, [type]: file }));
    };

    const saveDeletePhotoType = (type, e) => {
        e.preventDefault();
        e.stopPropagation();
        setDeletePhotoType(type);
        setIsDeleteModal(true);
    };

    const handleDeleteImg = () => {
        setIsChange((prev) => ({ ...prev, [deletePhotoType]: true }));
        setImgData((prev) => ({ ...prev, [deletePhotoType]: null }));
        setIsDeleteModal(false);
    };

    useEffect(() => {
        if (isEditMode) {
            // TODO: 00730 result 도 받아와야함.
            const chartId = patientInfo.attributes.ddhaim_charts.data[0].id;
            const rqData = {
                chart_id: chartId,
                image_type_cd: ['00729', '00730'],
                thumbnail_only_check: false,
            };
            viewerAPI.fileDownload(rqData, cookies).then(
                (response) => {
                    if (response.length > 1) {
                        setImgData((prev) => ({
                            ...prev,
                            original: response[0].presignedPath,
                            result: response[1].presignedPath,
                        }));
                    } else {
                        setImgData((prev) => ({
                            ...prev,
                            original: response[0].presignedPath,
                        }));
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
        }
    }, [isEditMode]);

    useEffect(() => {
        if (patientInfo) {
            setUserForm({
                id: patientInfo.attributes.patient_id,
                patientName: patientInfo.attributes.first_name,
                birthDate: patientInfo.attributes.birth_info,
                uploadDate:
                    patientInfo.attributes.ddhaim_charts.data[0].attributes
                        .ddhaim_chart_data.data[0].attributes.shoot_date,
                gender: patientInfo.attributes.gender,
                strapiId: patientInfo.id,
                chartId: patientInfo.attributes.ddhaim_charts.data[0].id,
                uuid: patientInfo.attributes.uuid,
            });
        }
    }, [patientInfo]);

    const handleEdit = (info, idx) => {
        setPatientInfo(info);
        if (idx === 0) {
            setIsEditModal(true);
            setIsEditMode(true);
        } else if (idx === 1) {
            setIsDeletePatientModal(true);
        } else if (idx === 2) {
            setIsShareModal(true);
        } else if (idx === 3) {
            setIsExportModal(true);
        }
    };

    useEffect(() => {
        if (isEditMode === false && isAddPatientModal === false) {
            setImgData({
                original: null,
                result: null,
            });
        }
    }, [isEditMode, isAddPatientModal]);

    const handleCloseModal = () => {
        setIsAddPatientModal(false);
        setIsDeletePatientModal(false);
        setIsEditModal(false);
        setIsExportModal(false);
        setIsShareModal(false);
        setIsEditMode(false);
        setPatientInfo('');

        getAllChart.refetch();
    };

    const getAllChart = useQuery(GET_ALL_PATIENT_LIST_OF_DOCTOR, {
        fetchPolicy: 'no-cache',
        variables: {
            filters: {
                ddhaim_user: {
                    id: {
                        eq: cookies.i.user_info.id,
                    },
                },
                deletedAt: {
                    null: true,
                },
                ddhaim_charts: {
                    ddhaim_chart_data: {
                        image_type_cd: {
                            in: ['00729', '00730'],
                        },
                    },
                },
            },
            ddhaimChartDataFilters2: {
                deletedAt: {
                    null: true,
                },
            },
            pagination: {
                pageSize: 10,
                page: Number(currentPage),
            },
            sort: [`${sortingItem}:${sortingOrder}`],
        },
        onError: (e) => {
            console.log(e);
        },
    });

    useEffect(() => {
        if (getAllChart.data) {
            if (searchInput === '') {
                setTotal(getAllChart.data.ddhaimPatients.meta.pagination.total);
                setPatientList(getAllChart.data.ddhaimPatients.data);
            }
        }
    }, [getAllChart, sortingItem, searchInput, isAddPatientModal]);

    const handlePageClick = (e) => {
        if (e === -1) {
            if (currentPage > 1) {
                setCurrentPage(currentPage - 1);
            }
        } else if (e === 1) {
            if (currentPage < totalPages) {
                setCurrentPage(currentPage + 1);
            }
        }
        if (searchInput) setSearchInput(true);
    };

    const handleSort = (value) => {
        setSortingItem(value);

        return sortingOrder === 'desc'
            ? setSortingOrder('asc')
            : setSortingOrder('desc');
    };

    useEffect(() => {
        if (isExportModal) {
            const arr =
                patientInfo.attributes.ddhaim_charts.data[0].attributes
                    .ddhaim_chart_data.data;
            setExportList(arr);
        }
    }, [isExportModal]);

    return (
        <>
            <ChartComponent
                onDelete={() => setIsDeletePatientModal(true)}
                onShare={() => setIsShareModal(true)}
                onAddPatient={() => setIsAddPatientModal(true)}
                onEdit={(info, idx) => handleEdit(info, idx)}
                patientList={patientList}
                setPatientList={setPatientList}
                setTotal={setTotal}
                searchInput={searchInput}
                setSearchInput={setSearchInput}
                currentPage={currentPage}
                sortingItem={sortingItem}
                sortingOrder={sortingOrder}
                onSort={handleSort}
                onPageClick={handlePageClick}
                totalPages={totalPages}
            />
            <DeletePatientModal
                open={isDeletePatientModal}
                closeDelete={handleCloseModal}
                userForm={userForm}
            />
            {isExportModal && (
                <ExportModal
                    open={isExportModal}
                    exportList={exportList}
                    setExportList={setIsExportModal}
                    closeExport={handleCloseModal}
                    userForm={userForm}
                />
            )}
            {isShareModal && (
                <ShareModal
                    userForm={userForm}
                    open={isShareModal}
                    closeShare={handleCloseModal}
                    setIsSuccess={setIsSuccess}
                />
            )}

            {isSuccess && (
                <DefaultModal
                    open={isSuccess}
                    title='Success'
                    text='Sent an email successfully'
                    onClose={() => {
                        setIsSuccess(false);
                    }}
                />
            )}
            {isAddPatientModal && (
                <AddNewPatientModal
                    open={isAddPatientModal}
                    closeModal={handleCloseModal}
                    imgChange={handleImageInput}
                    src={imgData}
                    onDelete={saveDeletePhotoType}
                    imgFile={imgFile}
                    setUserForm={setUserForm}
                    userForm={userForm}
                />
            )}
            {isEditModal && (
                <EditPatientModal
                    open={isEditModal}
                    closeModal={handleCloseModal}
                    src={imgData}
                    imgChange={handleImageInput}
                    onDelete={saveDeletePhotoType}
                    setUserForm={setUserForm}
                    userForm={userForm}
                    imgData={imgData}
                    isChange={isChange}
                    imgFile={imgFile}
                    setImgData={setImgData}
                    patientInfo={patientInfo}
                    setIsChange={setIsChange}
                />
            )}
            <DeleteModal
                open={isDeleteModal}
                closeDelete={() => setIsDeleteModal(false)}
                onDelete={handleDeleteImg}
                isChange={isChange}
            />
        </>
    );
};

export default ChartContainer;
