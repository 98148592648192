import qs from 'qs';

import { apiPath } from 'modules/defines/paths';
import { httpAPI } from './http';

const ShareAPI = (() => {
    function ShareAPIConstructor() {}
    ShareAPIConstructor.prototype = {
        share(data, cookies) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPath.share, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        Authorization: `bearer ${cookies.i.token}`,
                    },
                    data: qs.stringify(data),
                })
                    .then((response) => {
                        resolve(response);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },

        pin(data) {
            return new Promise((resolve, reject) => {
                httpAPI('', apiPath.pin, { data: qs.stringify(data) })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    };

    return ShareAPIConstructor;
})();

export const shareAPI = new ShareAPI();
