import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

const usePathnameStore = create((set, get) => ({
    pathname: window.location.pathname,

    methods: {
        setPathname: (newPathname) => set({ pathname: newPathname }),
    },
}));

export const usePathnameStoreMethods = () =>
    usePathnameStore((state) => state.methods, shallow);

export const usePathnameStoreAndMethods = (stateGetter, flags) => [
    usePathnameStore(stateGetter, flags),
    usePathnameStore((state) => state.methods, shallow),
];

export default usePathnameStore;
