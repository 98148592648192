import { VIEWER_STEP } from 'modules/defines/values';
import { create } from 'zustand';
import { shallow } from 'zustand/shallow';
import { persist, createJSONStorage } from 'zustand/middleware';

const useViewerStepStore = create(
    persist(
        (set, get) => ({
            step: 0,
            previous: false,
            nextStep: 0,
            setStep: (state) => {
                set({ step: state });
            },
            setPrevious: (state) => {
                set({ previous: state });
            },
            setNextStep: (state) => {
                set({ nextStep: state });
            },
        }),
        {
            name: 'step-state',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export const useViewerStepStoreMethods = () =>
    useViewerStepStore((state) => state.methods, shallow);
export const useViewerStepAndMethods = (stateGetter, flags) => [
    useViewerStepStore(stateGetter, flags),
    useViewerStepStore((state) => state.methods, shallow),
];

export default useViewerStepStore;
