import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

import styled from 'styled-components/macro';
import { fonts, palette, size } from 'modules/defines/styles';
import DDHLogo from 'assets/ddh-logo.svg';
import { useCookies } from 'react-cookie';
import { Right } from 'assets/right-arrow.svg';

const HeaderWrapper = styled.div`
    width: 100%;
    min-height: ${size.header}px;
    background-color: ${palette.white};
    padding-left: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const LinkWrapper = styled(Link)`
    display: flex;
    height: 100%;
    align-items: center;
    text-decoration: none;
`;

const LogoWrapper = styled.img`
    margin-right: 7px;
`;

const Title = styled.span`
    color: ${palette.primary.black};
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
`;

const Title2 = styled.span`
    color: ${palette.primary.black};
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    padding-left: 5px;
`;

const Header = (props) => {
    const [cookies] = useCookies();
    return (
        <HeaderWrapper className='headerWrapper'>
            <LinkWrapper to={cookies.token ? props.logoLink : null}>
                <LogoWrapper src={DDHLogo} alt='ddhaim ashely' />
                <>
                    <Title>DDH |</Title>
                    <Title2> JUDY</Title2>
                </>
            </LinkWrapper>

            {props.children}
        </HeaderWrapper>
    );
};

export default Header;
