import React, { useState } from 'react';
import {
    ComposedModal,
    ModalHeader,
    ModalFooter,
    Button,
    ModalBody,
    TextInput,
    Theme,
} from '@carbon/react';
import { styled } from 'styled-components';
import { shareAPI } from 'modules/api/share';
import { useCookies } from 'react-cookie';
import DefaultModal from 'components/Chart/modal/DefaultModal';

const GridRow = styled.div`
    display: flex;
    width: 100%;
    height: 20px;
    margin-bottom: 1rem;
`;

const CheckBoxCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    aspect-ratio: 1;
    margin-right: 8px;
`;

const ShareModal = ({ open, closeShare, userForm, setIsSuccess }) => {
    const [password, setPassword] = useState();
    const [email, setEmail] = useState('');
    const [cookies] = useCookies();

    const handleShare = () => {
        const rqData = {
            email: email,
            pin: password,
            chart_id: userForm.chartId,
            patient_id: userForm.id,
            link: `https://dev.judy.ddhinc.net/share/${userForm.uuid}`,
        };
        shareAPI.share(rqData, cookies).then(
            (response) => {
                closeShare();
                setIsSuccess(true);
            },
            (error) => console.log(error)
        );
    };

    return (
        <Theme theme='g10'>
            <ComposedModal open={open} size='xs' onClose={closeShare}>
                <ModalHeader>
                    <h1>Share</h1>
                </ModalHeader>
                <ModalBody>
                    <p style={{ marginBottom: '1rem' }}>
                        Enter your patient's email address, and PIN, to allow
                        your patient to view the simulation.
                    </p>
                    <TextInput
                        data-modal-primary-focus
                        labelText='Email'
                        placeholder='Example@gmail.com'
                        onChange={(e) => setEmail(e.target.value)}
                        style={{ marginBottom: '1rem' }}
                        type='email'
                        value={email}
                    />
                    {/* <GridRow>
                        <CheckBoxCell>
                            <input
                                type='checkbox'
                                checked={password}
                                onChange={() => setPassword(!password)}
                            />
                        </CheckBoxCell>
                        비밀번호 설정
                    </GridRow> */}
                    <TextInput
                        data-modal-primary-focus
                        labelText='PIN'
                        placeholder='type your PIN'
                        style={{ marginBottom: '1rem' }}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button kind='primary' onClick={handleShare}>
                        Send
                    </Button>
                </ModalFooter>
            </ComposedModal>
        </Theme>
    );
};

export default ShareModal;
