import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useAIresultStore = create(
    persist(
        (set, get) => ({
            lipPoints: [
                [888, 714],
                [910, 715],
                [938, 718],
                [961, 722],
                [994, 723],
                [1021, 723],
                [1050, 718],
                [1075, 715],
                [1095, 713],
                [1082, 738],
                [1059, 757],
                [1028, 771],
                [991, 777],
                [959, 772],
                [934, 763],
                [908, 741],
            ],
            eyePoints: [
                [897, 499],
                [1105, 499],
            ],
            xCoords: {
                smallestXCoord: [0, 0],
                largestXCoord: [0, 0],
            },
            setXCoords: (coords) => {
                set({ xCoords: coords });
            },
            setLipPoints: (points) => {
                set({ lipPoints: points });
            },
            setEyePoints: (points) => {
                set({ eyePoints: points });
            },
            initialized: () =>
                set({
                    lipPoints: [
                        [888, 714],
                        [910, 715],
                        [938, 718],
                        [961, 722],
                        [994, 723],
                        [1021, 723],
                        [1050, 718],
                        [1075, 715],
                        [1095, 713],
                        [1082, 738],
                        [1059, 757],
                        [1028, 771],
                        [991, 777],
                        [959, 772],
                        [934, 763],
                        [908, 741],
                    ],
                    eyePoints: [
                        [897, 499],
                        [1105, 499],
                    ],
                    xCoords: {
                        smallestXCoord: [0, 0],
                        largestXCoord: [0, 0],
                    },
                }),
        }),
        {
            name: 'lip-eye',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useAIresultStore;
