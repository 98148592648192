import React, { useEffect } from 'react';
import ChartContainer from 'containers/ChartContainer';

import { usePathnameStoreMethods } from '../modules/stores/PathnameStore';

const Chart = () => {
    const { setPathname } = usePathnameStoreMethods();

    useEffect(() => {
        setPathname(window.location.pathname);
    }, []);

    return <ChartContainer />;
};

export default Chart;
