import React from 'react';

const ResultFace = () => (
    <svg
        width='123'
        height='146'
        viewBox='0 0 123 146'
        fill='none'
        // xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M90.2577 6.23934L90.3074 6.26401C93.9595 7.87991 97.3881 10.0015 100.357 12.6536C110.655 21.8309 116.953 35.2885 119.96 48.8818C125.599 74.3538 120.842 102.428 105.078 123.299C102.133 127.185 98.8042 130.787 95.1645 134.043C78.8043 148.66 53.6987 148.66 37.3384 134.043C33.6987 130.787 30.357 127.185 27.4254 123.299C11.6614 102.428 6.90363 74.3538 12.5434 48.8818C15.5496 35.2885 21.8477 21.8309 32.1459 12.6536C35.1272 10.0015 38.5434 7.89224 42.1956 6.26401L42.2452 6.23934C57.4751 -0.754666 75.0279 -0.754666 90.2577 6.23934Z'
            fill='url(#paint0_radial_467_118658)'
            fill-opacity='0.6'
        />
        <path
            d='M58.631 93.8573C58.631 93.8573 58.6061 93.8573 58.5937 93.8573C54.7552 93.7957 50.4074 93.7216 46.4322 91.9084C42.5192 90.1198 38.5564 85.8395 39.103 80.6588C39.5999 75.8728 43.4011 72.9123 46.7428 70.2973C47.339 69.8285 47.9353 69.3598 48.5067 68.8911C55.5005 63.0936 59.3763 53.5339 58.3949 44.5539C57.3639 35.2039 50.9291 26.8654 42.7428 24.2873C34.1589 21.5736 23.8359 25.9649 20.6558 33.6744C20.1465 34.9079 18.7303 35.4876 17.5005 34.9942C16.2583 34.4885 15.6744 33.0823 16.1713 31.8611C20.3204 21.7957 33.1651 16.2202 44.2086 19.711C54.3328 22.9058 61.9726 32.6876 63.2148 44.0482C64.3949 54.7551 59.9477 65.6963 51.6123 72.6039C51.0161 73.0974 50.3825 73.6031 49.7366 74.0965C46.8918 76.3168 44.2086 78.4261 43.9229 81.1768C43.6372 83.8782 45.9974 86.4316 48.4571 87.5664C51.5005 88.9603 55.1527 89.022 58.6682 89.0836C60.0098 89.1083 61.0782 90.2061 61.0533 91.526C61.0285 92.8458 59.9477 93.8943 58.631 93.8943V93.8573Z'
            fill='black'
        />
        <path
            d='M117.774 39.2609C116.818 39.2609 115.911 38.6935 115.526 37.7683C112.557 30.54 105.004 25.3099 97.153 25.0262C89.2524 24.7548 81.3891 29.4545 77.9232 36.4485C77.3269 37.645 75.8735 38.126 74.681 37.5463C73.476 36.9542 72.9915 35.5233 73.5754 34.3268C77.8611 25.6799 87.6251 19.8701 97.3269 20.2155C107.029 20.5485 116.358 27.0245 120.023 35.9427C120.532 37.1762 119.936 38.5824 118.693 39.0759C118.395 39.1992 118.085 39.2609 117.774 39.2609Z'
            fill='black'
        />
        <path
            d='M99.5131 48.3886C99.5131 51.1517 97.2522 53.3967 94.4696 53.3967C91.687 53.3967 89.4261 51.1517 89.4261 48.3886C89.4261 45.6255 91.687 43.3806 94.4696 43.3806C97.2522 43.3806 99.5131 45.6255 99.5131 48.3886Z'
            fill='black'
        />
        <path
            d='M42.37 53.3967C45.1554 53.3967 47.4135 51.1545 47.4135 48.3886C47.4135 45.6227 45.1554 43.3806 42.37 43.3806C39.5845 43.3806 37.3265 45.6227 37.3265 48.3886C37.3265 51.1545 39.5845 53.3967 42.37 53.3967Z'
            fill='black'
        />
        <path
            d='M63.3642 131.096C47.4635 131.096 32.4076 120.796 26.7555 106.043C26.4449 105.229 26.6064 104.304 27.1778 103.625C27.7493 102.959 28.6437 102.651 29.5132 102.824C51.7244 107.314 75.1902 107.166 97.3393 102.392C98.1965 102.207 99.1033 102.503 99.6747 103.157C100.259 103.823 100.432 104.736 100.122 105.562C94.7182 120.253 79.3517 130.985 63.6126 131.083H63.389L63.3642 131.096ZM33.2027 108.424C39.1406 118.983 51.1654 126.285 63.3642 126.285H63.5629C75.8486 126.211 87.8486 118.748 93.6871 108.029C83.4014 109.941 72.8921 110.915 62.3704 110.915C52.5816 110.915 42.7927 110.077 33.2027 108.424Z'
            fill='black'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M91.7582 117.904C68.9247 123.503 44.3016 120.244 34.7581 117.904L35.8452 113.998C44.9394 116.228 68.7742 119.367 90.6711 113.998L91.7582 117.904Z'
            fill='black'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M43.5657 124.998C36.6718 116.972 40.6897 108.346 43.5657 104.998L46.7581 106.012C44.1981 108.992 40.6054 116.821 46.7581 123.983L43.5657 124.998Z'
            fill='black'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M81.9506 124.998C88.8445 116.972 84.8266 108.346 81.9506 104.998L78.7581 106.012C81.3182 108.992 84.9109 116.821 78.7581 123.983L81.9506 124.998Z'
            fill='black'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M50.0459 127.998C47.7157 119.584 49.0711 110.517 50.0459 106.998L53.7581 107.437C52.8349 110.771 51.5332 119.524 53.7581 127.558L50.0459 127.998Z'
            fill='black'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M74.4704 127.998C76.8006 119.584 75.4451 110.517 74.4704 106.998L70.7581 107.437C71.6814 110.771 72.9831 119.524 70.7581 127.558L74.4704 127.998Z'
            fill='black'
        />
        <path
            fill-rule='evenodd'
            clip-rule='evenodd'
            d='M60.7581 127.998L60.7581 106.998L63.7581 106.998L63.7581 127.998L60.7581 127.998Z'
            fill='black'
        />
        <g clip-path='url(#clip0_467_118658)'>
            <path
                d='M23.1091 90.5793C23.9848 90.8549 24.8534 91.1599 25.7395 91.4024C26.3186 91.5604 26.7407 91.9022 26.7616 92.5122C26.7826 93.1295 26.3953 93.6182 25.8372 93.8167C24.8116 94.1805 23.765 94.4744 22.7289 94.7978C22.5161 94.886 22.3033 94.9779 22.0905 95.0661C18.6578 96.6204 16.6833 99.4904 15.5879 103.132C15.3123 104.047 15.1658 105.006 14.9599 105.943C14.9425 106.028 14.9285 106.116 14.9111 106.2C14.5692 106.472 14.2518 106.917 13.875 106.987C13.0413 107.137 12.5389 106.406 12.3854 105.278C12.0645 102.886 11.2621 100.685 9.84228 98.7554C8.72944 97.2415 7.38984 96.0252 5.72232 95.2314C5.64906 95.1984 5.56883 95.1873 5.48859 95.169C5.27579 95.0808 5.06299 94.9889 4.85019 94.9007C4.06178 94.6325 3.29082 94.2797 2.48148 94.118C1.74889 93.971 1.18724 93.6513 0.758148 93.0303V92.2219C1.12096 91.6596 1.6547 91.4171 2.26868 91.2738C4.78391 90.6822 7.06541 89.6129 8.69804 87.4154C9.99926 85.6626 10.8574 83.6489 11.4993 81.5359C11.9249 80.1359 12.2947 78.7175 12.6889 77.3101C12.9261 77.2072 13.1668 77.108 13.4041 77.0051H14.1715C14.4192 77.1631 14.6704 77.3211 14.9181 77.4791C15.3855 79.0776 15.8495 80.6797 16.317 82.2782C16.3484 82.3738 16.3798 82.4656 16.4147 82.5575C16.9868 83.8106 17.5554 85.0673 18.1275 86.3204C18.1624 86.3644 18.1973 86.4049 18.2322 86.449C19.2508 88.2202 20.6951 89.4695 22.4742 90.3184C22.687 90.4066 22.8998 90.4985 23.1126 90.5866L23.1091 90.5793ZM21.8149 92.6334C17.2345 90.5719 15.1937 86.5408 13.7843 81.7748C12.3784 86.5702 10.3167 90.5903 5.76419 92.6371C9.66087 94.2099 12.2075 97.0577 13.7913 101.037C15.3855 97.0173 17.9322 94.2135 21.8149 92.6334Z'
                fill='#312D40'
            />
        </g>
        <g clip-path='url(#clip1_467_118658)'>
            <path
                d='M41.6354 136.072C41.9048 136.155 42.1721 136.246 42.4447 136.319C42.6229 136.367 42.7528 136.469 42.7592 136.652C42.7657 136.837 42.6465 136.984 42.4748 137.043C42.1592 137.153 41.8372 137.241 41.5184 137.338C41.4529 137.364 41.3874 137.392 41.3219 137.418C40.2657 137.885 39.6582 138.746 39.3211 139.838C39.2363 140.113 39.1913 140.4 39.1279 140.681C39.1226 140.707 39.1183 140.733 39.1129 140.759C39.0077 140.84 38.91 140.973 38.7941 140.994C38.5376 141.04 38.383 140.82 38.3358 140.482C38.237 139.764 37.9901 139.104 37.5533 138.525C37.2109 138.071 36.7987 137.706 36.2856 137.468C36.263 137.458 36.2384 137.455 36.2137 137.449C36.1482 137.423 36.0827 137.395 36.0172 137.369C35.7747 137.288 35.5374 137.182 35.2884 137.134C35.063 137.09 34.8902 136.994 34.7581 136.808V136.565C34.8698 136.396 35.034 136.324 35.2229 136.281C35.9968 136.103 36.6988 135.782 37.2012 135.123C37.6016 134.597 37.8656 133.993 38.0631 133.359C38.1941 132.939 38.3079 132.514 38.4292 132.091C38.5021 132.061 38.5762 132.031 38.6492 132H38.8853C38.9616 132.047 39.0388 132.095 39.1151 132.142C39.2589 132.622 39.4016 133.102 39.5455 133.582C39.5551 133.611 39.5648 133.638 39.5755 133.666C39.7516 134.042 39.9265 134.419 40.1026 134.795C40.1133 134.808 40.124 134.82 40.1348 134.833C40.4482 135.364 40.8926 135.739 41.44 135.994C41.5055 136.02 41.571 136.048 41.6365 136.074L41.6354 136.072ZM41.2372 136.688C39.8278 136.07 39.1998 134.861 38.7662 133.431C38.3336 134.869 37.6992 136.076 36.2985 136.69C37.4974 137.161 38.281 138.016 38.7683 139.21C39.2589 138.004 40.0425 137.162 41.2372 136.688Z'
                fill='#312D40'
            />
        </g>
        <g clip-path='url(#clip2_467_118658)'>
            <path
                d='M113.951 119.958C114.625 120.16 115.293 120.383 115.975 120.561C116.42 120.677 116.745 120.928 116.761 121.375C116.777 121.828 116.479 122.186 116.05 122.332C115.261 122.598 114.456 122.814 113.659 123.051C113.495 123.116 113.331 123.183 113.168 123.248C110.527 124.388 109.008 126.492 108.166 129.163C107.954 129.834 107.841 130.537 107.683 131.224C107.669 131.286 107.658 131.351 107.645 131.413C107.382 131.612 107.138 131.938 106.848 131.99C106.207 132.1 105.82 131.564 105.702 130.737C105.455 128.982 104.838 127.368 103.746 125.953C102.89 124.843 101.859 123.951 100.577 123.369C100.52 123.345 100.459 123.337 100.397 123.323C100.233 123.259 100.07 123.191 99.9059 123.127C99.2994 122.93 98.7064 122.671 98.0838 122.553C97.5203 122.445 97.0882 122.21 96.7581 121.755V121.162C97.0372 120.75 97.4478 120.572 97.9201 120.467C99.8549 120.033 101.61 119.249 102.866 117.637C103.867 116.352 104.527 114.875 105.021 113.326C105.348 112.299 105.632 111.259 105.936 110.227C106.118 110.151 106.303 110.079 106.486 110.003H107.076C107.267 110.119 107.46 110.235 107.65 110.351C108.01 111.523 108.367 112.698 108.726 113.87C108.751 113.94 108.775 114.008 108.802 114.075C109.242 114.994 109.679 115.915 110.119 116.834C110.146 116.867 110.173 116.896 110.2 116.929C110.983 118.228 112.094 119.144 113.463 119.766C113.627 119.831 113.79 119.898 113.954 119.963L113.951 119.958ZM112.956 121.464C109.432 119.952 107.862 116.996 106.778 113.501C105.697 117.018 104.111 119.966 100.609 121.467C103.606 122.62 105.565 124.708 106.784 127.627C108.01 124.679 109.969 122.623 112.956 121.464Z'
                fill='#312D40'
            />
        </g>
        <defs>
            <radialGradient
                id='paint0_radial_467_118658'
                cx='0'
                cy='0'
                r='1'
                gradientUnits='userSpaceOnUse'
                gradientTransform='translate(66.2515 70.0728) rotate(90) scale(72.1604 56.1103)'
            >
                <stop stop-color='#0F62FE' />
                <stop offset='1' stop-color='#0F62FE' stop-opacity='0' />
            </radialGradient>
            <clipPath id='clip0_467_118658'>
                <rect
                    width='26'
                    height='30'
                    fill='white'
                    transform='translate(0.758148 76.9977)'
                />
            </clipPath>
            <clipPath id='clip1_467_118658'>
                <rect
                    width='8'
                    height='9'
                    fill='white'
                    transform='translate(34.7581 131.998)'
                />
            </clipPath>
            <clipPath id='clip2_467_118658'>
                <rect
                    width='20'
                    height='22'
                    fill='white'
                    transform='translate(96.7581 109.998)'
                />
            </clipPath>
        </defs>
    </svg>
);

export default ResultFace;
