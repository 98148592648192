import React, { useState } from 'react';
import { Button, Form, TextInput } from '@carbon/react';
import styled from 'styled-components';
import { authAPI } from 'modules/api/auth';
import { paths } from 'modules/defines/paths';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { palette } from 'modules/defines/styles';
import DefaultModal from 'components/Chart/modal/DefaultModal';

const LoginWrapper = styled.div`
    margin-top: 300px;
`;

const LoginForm = styled.div`
    width: 530px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;

    h2 {
        margin-bottom: 20px;
    }
`;
const Title = styled.span`
    color: ${palette.primary.black};
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
`;

const Title2 = styled.span`
    color: ${palette.primary.black};
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 30px;
    padding-left: 5px;
    margin-right: 15px;
`;

const TextInputProps = {
    className: 'some-class',
    id: 'test2',
    labelText: 'E-mail',
    placeholder: 'Typing E-mail',
};
const PasswordProps = {
    className: 'some-class',
    id: 'test3',
    labelText: 'Password',
};

const LoginContainer = () => {
    const navigate = useNavigate();
    const cookies = useCookies();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isError, setIsError] = useState(false);

    const handleChange = (e) => {
        if (e.target.name === 'email') setEmail(e.target.value);
        else setPassword(e.target.value);
    };

    const handleLogin = (e) => {
        if (e.key !== 'Enter' && e.type !== 'click') return;
        authAPI
            .login({
                email: email,
                password: password,
            })
            .then(
                (response) => {
                    console.log(response);
                    authAPI.loginSuccess(response, cookies);
                    navigate(paths.chart);
                },
                (error) => {
                    console.log(error);
                    setIsError(true);
                }
            );
    };

    return (
        <LoginWrapper>
            {isError && (
                <DefaultModal
                    onClose={() => setIsError(false)}
                    open={isError}
                    text='Unknown email or password'
                    title='Error'
                />
            )}
            <LoginForm>
                <h2>
                    {' '}
                    <Title>DDH |</Title>
                    <Title2> JUDY</Title2>
                    로그인{' '}
                </h2>
                <Form style={{ width: '100%' }}>
                    <TextInput
                        {...TextInputProps}
                        style={{ marginBottom: '20px' }}
                        value={email}
                        name='email'
                        id='email'
                        onChange={(e) => handleChange(e)}
                    />

                    <TextInput
                        type='password'
                        name='password'
                        id='password'
                        required
                        pattern='(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,}'
                        {...PasswordProps}
                        value={password}
                        style={{ marginBottom: '20px' }}
                        onChange={(e) => handleChange(e)}
                        onKeyDown={handleLogin}
                    />

                    <Button onClick={handleLogin}> 로그인 </Button>
                </Form>
            </LoginForm>
        </LoginWrapper>
    );
};

export default LoginContainer;
