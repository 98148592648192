import { gql } from '@apollo/client';

export const GET_ALL_PATIENT_LIST_OF_DOCTOR = gql`
    query ExampleQuery(
        $pagination: PaginationArg
        $filters: DdhaimPatientFiltersInput
        $sort: [String]
        $ddhaimChartDataFilters2: DdhaimChartDataFiltersInput
    ) {
        ddhaimPatients(
            pagination: $pagination
            filters: $filters
            sort: $sort
        ) {
            data {
                id
                attributes {
                    patient_id
                    first_name
                    birth_info
                    gender
                    createdAt
                    updatedAt
                    uuid
                    ddhaim_charts {
                        data {
                            id
                            attributes {
                                chart_id
                                createdAt
                                ddhaim_chart_data(
                                    filters: $ddhaimChartDataFilters2
                                ) {
                                    data {
                                        id
                                        attributes {
                                            updatedAt
                                            image_type_cd
                                            shoot_date
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            meta {
                pagination {
                    total
                }
            }
        }
    }
`;

export const CREATE_DSD_CHART = gql`
    mutation CreateDdhaimChart($data: DdhaimChartInput!) {
        createDdhaimChart(data: $data) {
            data {
                attributes {
                    ddhaim_patient {
                        data {
                            attributes {
                                uuid
                            }
                            id
                        }
                    }
                }
                id
            }
        }
    }
`;

export const CREATE_DSD_PATIENT = gql`
    mutation Mutation($data: DdhaimPatientInput!) {
        createDdhaimPatient(data: $data) {
            data {
                attributes {
                    uuid
                    patient_id
                    first_name
                }
                id
            }
        }
    }
`;

// export const DELETE_DDHAIM_PATIENT = gql`
//     mutation Mutation($deleteDdhaimPatientId: ID!) {
//         deleteDdhaimPatient(id: $deleteDdhaimPatientId) {
//             data {
//                 id
//             }
//         }
//     }
// `;

export const UPDATE_DSD_PATIENT = gql`
    mutation Mutation($data: DdhaimPatientInput!, $updateDdhaimPatientId: ID!) {
        updateDdhaimPatient(data: $data, id: $updateDdhaimPatientId) {
            data {
                attributes {
                    uuid
                }
            }
        }
    }
`;

export const DELETE_DSD_PATIENT = gql`
    mutation Mutation($updateDdhaimPatientId: ID!, $data: DdhaimPatientInput!) {
        updateDdhaimPatient(id: $updateDdhaimPatientId, data: $data) {
            data {
                attributes {
                    deletedAt
                }
            }
        }
    }
`;

export const DELETE_DSD_CHART = gql`
    mutation DeleteDdhaimPatient($deleteDdhaimChartId: ID!) {
        deleteDdhaimChart(id: $deleteDdhaimChartId) {
            data {
                attributes {
                    deletedAt
                }
            }
        }
    }
`;
