import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

const useLibraryInfoStore = create(
    persist(
        (set, get) => ({
            mxLib: '',
            mdLib: '',
            selected: 'maxillary',
            desiredCoord: [0, 0],
            imageWidth: 180,
            imageHeight: 40,
            mdWidth: 180,
            mdHeight: 40,
            mdCoord: [0, 0],
            setMaxilaryLib: (idx) => {
                set({ mxLib: idx });
            },
            setMandibularLib: (idx) => {
                set({ mdLib: idx });
            },
            setSelected: (state) => {
                set({ selected: state });
            },
            setDesiredCoord: (coord) => {
                set({ desiredCoord: coord });
            },
            setMdCoord: (coord) => {
                set({ mdCoord: coord });
            },
            setImageWidth: (width) => {
                set({ imageWidth: width });
            },
            setImageHeight: (height) => {
                set({ imageHeight: height });
            },
            setMdWidth: (width) => {
                set({ mdWidth: width });
            },
            setMdHeight: (height) => {
                set({ mdHeight: height });
            },
            initiallizedLibrary: () =>
                set({
                    mxLib: '',
                    mdLib: '',
                    selected: 'maxillary',
                    desiredCoord: [0, 0],
                    imageWidth: 180,
                    imageHeight: 40,
                    mdWidth: 180,
                    mdHeight: 40,
                }),
        }),
        {
            name: 'libraryInfo',
            storage: createJSONStorage(() => localStorage),
        }
    )
);

export default useLibraryInfoStore;
