import { create } from 'zustand';
import { shallow } from 'zustand/shallow';

const useDesignViewStore = create((set, get) => ({
    designView: true,

    methods: {
        setDesignView: (state) => set({ designView: state }),
    },
}));

export const useDesignViewStoreMethods = () =>
    useDesignViewStore((state) => state.methods, shallow);

export const useDesignViewStoreAndMethods = (stateGetter, flags) => [
    useDesignViewStore(stateGetter, flags),
    useDesignViewStore((state) => state.methods, shallow),
];

export default useDesignViewStore;
