import { zIndex } from 'modules/defines/styles';
import styled from 'styled-components';

export const SvgWrapper = styled.svg`
    ${(props) =>
        props.scale &&
        `
    transform: scale(${props.scale});
    `}
    position: absolute;
    top: 0;
`;

export const SvgGroup = styled.g`
    position: absolute;
    /* div {
        transform: ${(props) => `rotate(${props.rotationAngle}deg)`};
        transform-origin: 'center center';
    }
    svg {
        transform-origin: 'center center';
        transform: ${(props) => `rotate(${props.rotationAngle}deg)`};
    } */
`;
