import React, { useState } from 'react';
import styled from 'styled-components';
import { palette } from 'modules/defines/styles';
import { ArrowsVertical } from '@carbon/icons-react';

const GridRow = styled.div`
    display: flex;
    width: 100%;
    height: calc(100% / 12);
    background-color: ${palette.gray[0]};
    padding-right: 48px;
    padding-left: 20px;
`;

const GridCell = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 15px;
    width: ${(props) => props.w};
    height: 100%;
    font-size: 14px;
    align-items: center;
    font-weight: 600;
    &:hover {
        border-bottom: 3px solid ${palette.blue[3]};
        button {
            visibility: visible;
        }
    }

    button {
        visibility: hidden;
        border: 'none';
        background-color: 'inherit';
        cursor: 'pointer';
    }
`;

const PatientListHeader = ({ headerList, onSort }) => {
    return (
        <GridRow>
            {headerList.map((el, i) => (
                <GridCell key={i} w={el.width}>
                    {el.label}
                    {el.sortable && (
                        <button onClick={() => onSort(el.sortValue)}>
                            <ArrowsVertical />
                        </button>
                    )}
                </GridCell>
            ))}
        </GridRow>
    );
};

export default PatientListHeader;
