import React from 'react';

const Warning = () => (
    <svg
        width='16'
        height='16'
        viewBox='0 0 15 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
    >
        <path
            d='M7.5 0C3.65 0 0.5 3.15 0.5 7C0.5 10.85 3.65 14 7.5 14C11.35 14 14.5 10.85 14.5 7C14.5 3.15 11.35 0 7.5 0ZM6.95 3H8.05V8.5H6.95V3ZM7.5 11.5C7.1 11.5 6.75 11.15 6.75 10.75C6.75 10.35 7.1 10 7.5 10C7.9 10 8.25 10.35 8.25 10.75C8.25 11.15 7.9 11.5 7.5 11.5Z'
            fill='#DA1E28'
        />
    </svg>
);

export default Warning;
