import { palette, size } from 'modules/defines/styles';
import React from 'react';
import { styled } from 'styled-components';

const ChartLayoutWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 900px;
    height: 700px;
    min-width: 900px;
    min-height: ${size.minheight}px;
    margin-top: 30px;
    /* padding: 32px 30px; */
    background-color: ${palette.white};
`;

const ChartLayout = (props) => {
    return <ChartLayoutWrapper>{props.children}</ChartLayoutWrapper>;
};

export default ChartLayout;
