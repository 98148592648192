import React, { useEffect, useState } from 'react';
import { ComposedModal, ModalHeader, ModalFooter, Button } from '@carbon/react';
import { styled } from 'styled-components';
import { palette, fonts } from 'modules/defines/styles';
import { getDateFormat } from 'modules/utils/functions';
import { viewerAPI } from 'modules/api/viewer';
import { useCookies } from 'react-cookie';

const ExportBodyWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const GridRowTitle = styled.div`
    display: flex;
    width: 100%;
    height: 48px;
    background-color: ${palette.gray[0]};
`;
const GridRow = styled.div`
    display: flex;
    width: 100%;
    height: 48px;
    background-color: ${palette.gray[6]};
    &:hover {
        background-color: ${palette.blue[0]};
    }
`;

const CheckBoxCell = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    aspect-ratio: 1;
`;

const GridCell = styled.div`
    width: ${(props) => props.width};
    height: 100%;
    display: flex;
    align-items: center;
    padding: 15px 16px;
    font-size: 14px;
    font-weight: ${(props) =>
        props.type === 'title' ? fonts.weight.bold : fonts.weight.regular};
`;

const ExportModal = ({
    open,
    closeExport,
    exportList,
    setExportList,
    userForm,
}) => {
    const [allChecked, setAllChecked] = useState(false);
    const [cookies] = useCookies();
    const [isExport, setIsExport] = useState(false);
    const [checked, setChecked] = useState({
        original: false,
        result: false,
    });

    useEffect(() => {
        if (checked.original && checked.result) setAllChecked(true);
        else setAllChecked(false);
    }, [checked]);

    const handleAllChecked = () => {
        setAllChecked(!allChecked);
        if (!allChecked) {
            setChecked({ original: true, result: true });
        } else setChecked({ original: false, result: false });
    };

    const handleChecked = (check, idx) => {
        if (idx === 0) {
            setChecked((prev) => ({ ...prev, original: check }));
        } else if (idx === 1) {
            setChecked((prev) => ({ ...prev, result: check }));
        }
    };

    useEffect(() => {
        if (isExport) {
            const arr = [];
            if (checked.original) arr.push('00729');
            if (checked.result) arr.push('00730');

            if (arr.length !== 0) {
                const rqData = {
                    chart_id: userForm.chartId,
                    image_type_cd: arr,
                    thumbnail_only_check: false,
                };
                viewerAPI.fileDownload(rqData, cookies).then(
                    (response) => {
                        console.log(response);
                        response.map((d, i) => {
                            fetch(d.presignedPath)
                                .then((response) => response.blob())
                                .then((blob) => {
                                    const url = window.URL.createObjectURL(
                                        new Blob([blob])
                                    );
                                    const link = document.createElement('a');
                                    link.href = url;
                                    link.setAttribute(
                                        'download',
                                        'downloaded-image.jpeg'
                                    );
                                    document.body.appendChild(link);
                                    link.click();
                                    document.body.removeChild(link);
                                })
                                .catch((error) => {
                                    console.error(
                                        'Error downloading file:',
                                        error
                                    );
                                });
                        });
                        setIsExport(false);
                        closeExport();
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            } else {
                setIsExport(false);
            }
        }
    }, [isExport]);

    return (
        <ComposedModal
            open={open}
            modalHeading='Export'
            size='xs'
            onClose={closeExport}
        >
            <ModalHeader>
                <h1>Export</h1>
            </ModalHeader>
            <ExportBodyWrapper>
                <GridRowTitle>
                    <CheckBoxCell>
                        <input
                            type='checkbox'
                            checked={allChecked}
                            onChange={handleAllChecked}
                        />
                    </CheckBoxCell>
                    <GridCell width='15%' type='title'>
                        Type
                    </GridCell>
                    <GridCell width='33%' type='title'>
                        Name
                    </GridCell>
                    <GridCell width='10%' type='title'>
                        M/F
                    </GridCell>
                    <GridCell width='21%' type='title'>
                        Birth Date
                    </GridCell>
                    <GridCell width='21%' type='title'>
                        Edited
                    </GridCell>
                </GridRowTitle>
                {exportList.map((item, index) => (
                    <GridRow key={index}>
                        <CheckBoxCell>
                            <input
                                type='checkbox'
                                checked={
                                    index === 0
                                        ? checked.original
                                        : checked.result
                                }
                                onChange={(e) =>
                                    handleChecked(e.target.checked, index)
                                }
                            />
                        </CheckBoxCell>
                        <GridCell width='15%'>
                            {item.attributes.image_type_cd === '00729'
                                ? 'Original'
                                : 'Result'}
                        </GridCell>
                        <GridCell width='33%'>{userForm.patientName}</GridCell>
                        <GridCell width='10%'>
                            {userForm.gender === true ? 'F' : 'M'}
                        </GridCell>
                        <GridCell width='21%'>{userForm.birthDate}</GridCell>
                        <GridCell width='21%'>
                            {getDateFormat(item.attributes.updatedAt)}
                        </GridCell>
                    </GridRow>
                ))}
            </ExportBodyWrapper>
            <ModalFooter>
                <Button kind='primary' onClick={() => setIsExport(true)}>
                    Export
                </Button>
            </ModalFooter>
        </ComposedModal>
    );
};

export default ExportModal;
